import { HStack, View, Image, Box } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import ContactoSimple from "../components/ContactoSimple";

const Contacto = () => {
  return (
    <View w="100%">
      <FotoPortada
        url="https://ladressepatrimoine.com/img/contactanos.jpg"
        texto="Contáctanos"
        posicion={"center"}
      />
      <Box w="90%" alignSelf="center">
        <h1 style={{ fontFamily: "Graphik", textAlign: "center" }}>
          Contacte con un asesor de inversión
        </h1>
      </Box>
      <Box w="90%" alignSelf="center">
        <h3
          style={{
            fontFamily: "Graphik",
            fontWeight: "normal",
            lineHeight: 1.5,
            textAlign: "justify",
          }}
        >
          Para un estudio personalizado de su proyecto de inversión en alquiler,
          complete los campos a continuación. Uno de nuestros colaboradores se
          pondrá en contacto con usted por teléfono lo antes posible para
          revisar sus necesidades y organizar una llamada.
        </h3>
      </Box>

      <HStack
        w="90%"
        alignSelf="center"
        alignItems={"center"}
        justifyContent={"center"}
        mb={10}
      >
        <Image
          source={{
            uri: "https://ladressepatrimoine.com/img/contacto2.jpg",
          }}
          alt="Alternate Text"
          w="50%"
          h={"540px"}
          display={{ base: "none", md: "flex" }}
        />
        <Box w={{ base: "90%", md: "50%" }}>
          <ContactoSimple />
        </Box>
      </HStack>
    </View>
  );
};
export default Contacto;
