import { View, Box, VStack, Button, Center } from "native-base";
import React, { useState, useEffect } from "react";
import RazonesZStack from "../components/home/RaonesZStack";
import InicioComponent from "../components/home/InicioComponent";
import ProyectosHome from "../components/home/ProyectosHome";
import ContactoFooter from "../components/ContactoFooter";
import FlatListInmueblesPortada from "../components/FlatListInmueblesPortada";
import InvitacionRegistro from "../components/home/InvitacionRegistro";
import BotonesInicio from "../components/home/BotonesInicio";
import Buscador from "../components/home/Buscador";
import InvitacionInmueble from "../components/global/InvitacionInmueble";
import Popup from "reactjs-popup";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContactoSimple from "../components/ContactoSimple";

const Home = () => {
  return (
    <View flex={1}>
      {/* <InicioComponent /> */}

      <Buscador />
      <InvitacionRegistro />
      <BotonesInicio />
      <InvitacionInmueble />

      <Box w="90%" alignSelf="center" my={10}>
        <ProyectosHome />
      </Box>

      <Box w="90%" my={10} alignSelf="center">
        <FlatListInmueblesPortada />
      </Box>
      <Box w="90%" alignSelf="center">
        <RazonesZStack />
      </Box>
      <ContactoFooter />
    </View>
  );
};
export default Home;
