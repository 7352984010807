import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch,
} from '@mui/material';
import { VStack, View } from "native-base";
import fetchPost from '../../../helper/fetchPost';
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";


const ApartadosAdmin = () => {
    const { tipo } = useUser();

    const [apartados, setApartados] = useState([
        {
            ID: 1,
            Desarrolladora: "Desarrolladora A",
            Titulo: "Apartamento 101",
            Direccion: "Calle 123, Ciudad",
            Precio: 150000,
            Unidades: 20,
            Broker: "Elmer Homero",
            Status: "Pendiente",
            Fecha: "2022-03-01",
        },
        {
            ID: 2,
            Desarrolladora: "Desarrolladora B",
            Titulo: "Casa en el Bosque",
            Direccion: "Bosque 456, Pueblo",
            Precio: 250000,
            Unidades: 120,
            Broker: "Abdiel Broker",
            Status: "Reservado",
            Fecha: "2022-03-02",
        },
        // Puedes agregar más objetos de apartados según sea necesario
    ]);
    const handleStatusChange = (id) => {
        // Lógica para cambiar el estado del apartado
        const updatedApartados = apartados.map(apartado => {
            if (apartado.ID === id) {
                apartado.Status = apartado.Status === "Reservado" ? "Pendiente" : "Reservado";
            }
            return apartado;
        });

        setApartados(updatedApartados);
    };

    const headerText = {
        color: 'white',
        fontWeight: 'bold',
        borderWidth: 1,
        borderColor: '#eeeeee',
        borderStyle: 'solid',
    };

    if (tipo !== "0") {
        return <Login />
    }

    return (
        <View mx={12} flex={1} alignSelf={"center"} my={20}>
            <h1 style={{ textAlign: "center" }}>
                Solicitudes de apartado recibidas
            </h1>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#11003a' }}>
                            <TableCell style={headerText}>ID</TableCell>
                            <TableCell style={headerText}>Desarrolladora</TableCell>
                            <TableCell style={headerText}>Titulo</TableCell>
                            <TableCell style={headerText}>Direccion</TableCell>
                            <TableCell style={headerText}>Precio</TableCell>
                            <TableCell style={headerText}>Unidades</TableCell>
                            <TableCell style={headerText}>Broker</TableCell>
                            <TableCell style={headerText}>Status</TableCell>
                            <TableCell style={headerText}>Fecha de solicitud</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apartados.map((row) => (
                            <TableRow key={row.ID}>
                                <TableCell>{row.ID}</TableCell>
                                <TableCell>{row.Desarrolladora}</TableCell>
                                <TableCell>{row.Titulo}</TableCell>
                                <TableCell>{row.Direccion}</TableCell>
                                <TableCell>{row.Precio}</TableCell>
                                <TableCell>{row.Unidades}</TableCell>
                                <TableCell>{row.Broker}</TableCell>
                                <TableCell style={{ display: 'flex', flexDirection: 'column' }}>

                                    {row.Status}
                                    <Switch
                                        checked={row.Status === 'Reservado'}
                                        onChange={() => handleStatusChange(row.ID)}
                                    />



                                </TableCell>
                                <TableCell>{row.Fecha}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </View>
    );
};
export default ApartadosAdmin;