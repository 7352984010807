import { Pressable, Stack, VStack, Heading, Text, Center } from "native-base";
import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import unete from "../../Lotties/unete.json";
import buscarcasa from "../../Lotties/buscarcasa.json";
import casavender from "../../Lotties/casavender.json";

const BotonesInicio = () => {
  const navigate = useNavigate();
  const handleClick = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const BotonBanner = ({ id, url, titulo, texto, boton, nav }) => {
    return (
      <Pressable
        onPress={() => handleClick(nav)}
        w={{ base: 64, sm: 80, md: 80 }}
        px={4}
        h={"550px"}
        alignSelf={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        bg="#fff"
        borderRadius={10}
        borderWidth={0.8}
        borderColor={"muted.300"}
        shadow={7}
      >
        <VStack
          alignSelf={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Center w="100%" h={"300px"}>
            <Lottie
              animationData={url}
              style={{ height: "260px", width: "260px" }}
              loop={true}
            />
          </Center>

          <Heading fontFamily={"Graphik"} textAlign={"center"} px={2}>
            {titulo}
          </Heading>
          <Text
            px={2}
            my={4}
            fontSize={{ base: "sm", md: "md" }}
            fontFamily={"Garet"}
            textAlign={"center"}
          >
            {texto}
          </Text>
          <Center
            borderWidth={1}
            borderRadius={20}
            mb={6}
            p={4}
            shadow={7}
            bg={"#04324c"}
          >
            <Text
              bold
              fontSize={{ base: "lg", md: "xl" }}
              color={"#fff"}
              fontFamily={"Graphik"}
            >
              {boton}
            </Text>
          </Center>
        </VStack>
      </Pressable>
    );
  };

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      w="100%"
      mt={12}
      space={6}
      alignSelf={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={""}
    >
      <BotonBanner
        nav="/properties"
        id={1}
        url={buscarcasa}
        titulo="Busca desarrollos"
        texto="Explora una variedad de opciones para encontrar tu próximo hogar."
        boton="Buscar casas"
      />
      <BotonBanner
        nav="/proyect"
        id={2}
        url={casavender}
        titulo="Vende una propiedad"
        texto="Pon en venta tu unidad de una manera fácil y segura."
        boton="Ver tus opciones"
      />
      <BotonBanner
        nav="/contact"
        id={3}
        url={unete}
        titulo="Únete a nuestro equipo"
        texto="Forma parte de nuestro equipo y contribuye al éxito de nuestra misión compartida."
        boton="Contáctanos"
      />
    </Stack>
  );
};
export default BotonesInicio;
