import React, { useState, useEffect } from "react";
import {
  HStack,
  Text,
  View,
  Box,
  Select,
  Input,
  Button,
  VStack,
} from "native-base";
import FotoPortada from "../components/global/FotoPortada";

const ConversorDivisas = () => {
  const [precioUSD, setPrecioUSD] = useState(null);
  const [monto, setMonto] = useState("");
  const [monedaOrigen, setMonedaOrigen] = useState("USD");
  const [monedaDestino, setMonedaDestino] = useState("MXN");
  const [resultado, setResultado] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch(
          `https://open.er-api.com/v6/latest/${monedaOrigen}`
        );
        const data = await response.json();

        const tasaDeCambio = Number(data.rates[monedaDestino].toFixed(2));
        setPrecioUSD(tasaDeCambio);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, [monedaOrigen, monedaDestino]);

  const convertirDivisas = () => {
    const montoConvertido = (monto * precioUSD).toFixed(2);
    setResultado(
      `$${monto} ${monedaOrigen} = $${montoConvertido} ${monedaDestino}`
    );
  };

  return (
    <View>
      <FotoPortada
        url="https://ladressepatrimoine.com/img/conversor.jpg"
        texto="Conversor de divisas"
        posicion="center"
      />
      <Box>
        <h1 style={{ fontFamily: "Graphik", textAlign: "center" }}>
          Conversor de divisas
        </h1>
      </Box>
      <Text alignSelf={"center"} textAlign={"center"}>
        Favor de poner la cantidad sólo con numeros
      </Text>

      <VStack
        space={4}
        my={8}
        flex={1}
        alignSelf="center"
        borderWidth={1}
        borderColor={"muted.300"}
        borderRadius={10}
        p={5}
        shadow={5}
      >
        <HStack justifyContent="space-between">
          <Text>Selecciona moneda de origen:</Text>
          <Select
            selectedValue={monedaOrigen}
            minWidth={120}
            accessibilityLabel="Selecciona moneda de origen:"
            placeholder="Selecciona moneda de origen:"
            onValueChange={(itemValue) => setMonedaOrigen(itemValue)}
          >
            <Select.Item label="USD" value="USD" />
            <Select.Item label="MXN" value="MXN" />
            <Select.Item label="EUR" value="EUR" />
            <Select.Item label="CAD" value="CAD" />
          </Select>
        </HStack>

        <HStack justifyContent="space-between">
          <Text>Ingresa el monto:</Text>
          <Input
            placeholder="Ingresa el monto:"
            keyboardType="numeric"
            value={monto}
            onChangeText={(value) => setMonto(value)}
          />
        </HStack>

        <HStack justifyContent="space-between" space={3}>
          <Text>Selecciona moneda de destino:</Text>
          <Select
            selectedValue={monedaDestino}
            minWidth={120}
            accessibilityLabel="Selecciona moneda de destino:"
            placeholder="Selecciona moneda de destino:"
            onValueChange={(itemValue) => setMonedaDestino(itemValue)}
          >
            <Select.Item label="USD" value="USD" />
            <Select.Item label="MXN" value="MXN" />
            <Select.Item label="EUR" value="EUR" />
            <Select.Item label="CAD" value="CAD" />
          </Select>
        </HStack>

        <Button shadow={6} textAlign="center" onPress={convertirDivisas}>
          Convertir
        </Button>
      </VStack>

      {resultado && (
        <Text
          my={8}
          fontSize="xl"
          bold
          alignSelf="center"
          textAlign="center"
          borderWidth={1}
          shadow={7}
          borderRadius={10}
          borderColor={"muted.300"}
          p={4}
        >
          Resultado: {"\n"}
          {resultado}
        </Text>
      )}
    </View>
  );
};

export default ConversorDivisas;
