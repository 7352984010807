import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import InfoUserComponent from "../../components/users/InfoUserComponent";


const UserInfo = () => {

    const { tipo, userId } = useUser();


    return (
        <>
            {userId !== null && (
                tipo === "0" || "1" || "2" || "3" ? (
                    <InfoUserComponent />
                ) : (
                    <Login />
                )
            )}
        </>
    );
}

export default UserInfo; 