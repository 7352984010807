import React from "react";
import { Text, View, Stack, Heading, Box } from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList } from "react-icons/pi";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";
import OpcionUser from "../../../components/global/OpcionUser";
//import { MdCardMembership } from "react-icons/md";
//import { TbHomeExclamation } from "react-icons/tb";
import { animateScroll as scroll } from "react-scroll";
import { TbHomeHeart } from "react-icons/tb";
import { FaHouseUser } from "react-icons/fa6";

const BrokerPanel = () => {
  const { logout, nombre, apellido, correo, userId } = useUser();

  const navigate = useNavigate();

  const handleSalir = () => {
    // Lógica para el inicio de sesión
    logout();
    scroll.scrollToTop();
    navigate("/");
  };

  const UserNav = (nav) => {
    navigate(nav);
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };

  return (
    <View mt={[12, 12, 20, 24]}>
      <Heading bold fontSize={"2xl"} textAlign="center" mt={3}>
        BIENVENIDO
      </Heading>
      <Stack my={3} ml="20%">
        <Text bold fontSize={"2xl"} textAlign="justify" px={10}>
          Cuenta
        </Text>

        <Text bold fontSize={"lg"} px={10}>
          {nombre} {apellido}.
        </Text>
        <Text fontWeight={"semibold"} fontSize={"md"} px={10}>
          {correo}
        </Text>
      </Stack>

      <Box w="80%" alignSelf="center">
        <div style={stackContainerStyle}>
          <OpcionUser
            titulo="Información personal"
            texto="Visualiza y edita tus datos personales"
            funcion={() => UserNav("/UserInfo")}
            icon={PiUserList}
          />
          <OpcionUser
            titulo="Favoritos"
            texto="Ver inmuebes favoritos"
            funcion={() => UserNav("/Favoritos")}
            icon={TbHomeHeart}
          />
          <OpcionUser
            titulo="Prospectos (Leads)"
            texto="Ver y asigna prospectos a tus brokers"
            funcion={() => UserNav("/SolicitudesBroker")}
            icon={FaHouseUser}
          />
          {/* <OpcionUser
                        titulo="Seguimiento Ventas realizadas"
                        texto="Revisa las reservaciones de tus usuarios"
                        funcion={() => UserNav("/VerVentas")}
                        icon={BsShop} /> */}
          {/* <OpcionUser
            titulo="Solicitudes de apartar inmueble"
            texto="Seguimiento las solicitudes para apartar un inmueble"
            funcion={() => UserNav("/SolicitudesBroker")}
            icon={TbHomeExclamation}
          /> */}
          {/* <OpcionUser
            titulo="Mi suscripción"
            texto="Información sobre mi suscripción"
            funcion={() => UserNav("/SuscripcionStatusDes")}
            icon={MdCardMembership}
          /> */}
          {/* <OpcionUser
                        titulo="Favoritos"
                        texto="Revisa las reservaciones de tus usuarios"
                        funcion={() => UserNav("/Favoritos")}
                        icon={BsShop} /> */}
        </div>
      </Box>

      <LogoutConfirmationPopover onConfirm={handleSalir} />
    </View>
  );
};
export default BrokerPanel;
