import { View } from "native-base";
import React from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";


const SolicitudesUser = () => {

    const { tipo, userId } = useUser();

    if (tipo !== "3") {
        return <Login />
    }

    return (
        <View mt={16}>

            <h1>
                Solicitudes Cliente
            </h1>

        </View>

    );

};
export default SolicitudesUser;