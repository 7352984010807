import React, { useState, useEffect } from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import fetchPost from "../../../helper/fetchPost";
import { Heading, View, Spinner, Button } from "native-base";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { secundario } from "../../../helper/colors";
import URL from "../../../helper/baseURL";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const VerBrokersDes = () => {
  const { tipo, userId } = useUser();
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Mantuve la función para obtener datos usuarip
  const verUsuarios = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append("id_usuario", userId);
      const BASE_URL = URL.BASE_URL;
      const url = `${BASE_URL}/desarrolladora/verBrokers`;
      const options = {
        method: "POST",
        body: data,
      };
      const res = await fetchPost(url, options);
      console.log(res.success);
      console.log(res.data);
      if (res.success === true) {
        setUsuarios(res.data);
        setLoading(false);
      }

      //setUsuarios(res);
    } catch (error) {
      console.error("Error al obtener datos de usuarios:", error);
    }
  };

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  useEffect(() => {
    verUsuarios();
  }, []);

  const handlePress = (id, nombre) => {
    scroll.scrollToTop();
    navigate(`/userinformation/${id}/${nombre}/`);
  };

  if (tipo !== "1") {
    return <Login />;
  }

  return (
    <View mx={16} flex={1} my={16}>
      <Heading my={3} alignSelf="center">
        Brokers vinculados
      </Heading>
      {loading ? (
        <Spinner size="2xl" my={4} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: secundario }}>
                <TableCell style={headerText}>ID</TableCell>
                <TableCell style={headerText}>Nombre</TableCell>
                <TableCell style={headerText}>Apellido</TableCell>
                <TableCell style={headerText}>Correo</TableCell>
                <TableCell style={headerText}>Teléfono</TableCell>

                <TableCell style={headerText}>Fecha de Creación</TableCell>
                <TableCell style={headerText}>Ver Perfil</TableCell>
                {/* <TableCell style={headerText}>Membresía</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.ID}</TableCell>
                  <TableCell>{row.nombre}</TableCell>
                  <TableCell>{row.apellidoP}</TableCell>
                  <TableCell>{row.correo}</TableCell>
                  <TableCell>{row.telefono}</TableCell>

                  <TableCell>{row.fechaRegistro}</TableCell>
                  <TableCell>
                    <Button onPress={() => handlePress(row.ID, row.nombre)}>
                      Ver Perfil
                    </Button>
                  </TableCell>
                  {/* <TableCell>{row.status}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </View>
  );
};
export default VerBrokersDes;
