import { View, Box, VStack, Image, Stack } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import ContactoFooter from "../components/ContactoFooter";
import InvitacionInmueble from "../components/global/InvitacionInmueble";

const TuProyecto = () => {
  return (
    <View>
      <FotoPortada
        url="https://ladressepatrimoine.com/img/tuproyecto.jpg"
        texto="Tu proyecto con nosotros"
      />
      <Box w="90%" alignSelf="center">
        <h1 style={{ fontFamily: "Graphik", textAlign: "center" }}>
          Potencia tu Desarrollo Inmobiliario con L'adresse Patrimoine
        </h1>
      </Box>
      <InvitacionInmueble />

      <Box w="90%" alignSelf="center">
        <h3
          style={{
            fontFamily: "Graphik",
            fontWeight: "normal",
            lineHeight: 1.5,
            textAlign: "justify",
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          En L'adresse Patrimoine, nos apasiona colaborar con desarrolladores
          inmobiliarios visionarios para llevar sus proyectos al siguiente
          nivel. Te invitamos a formar parte de nuestra plataforma y a
          aprovechar una serie de beneficios exclusivos al poner tu desarrollo
          con nosotros:
        </h3>
      </Box>

      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
      >
        <Box
          w={{ base: "80%", md: "40%" }}
          alignSelf={{ base: "center", md: "flex=start" }}
        >
          <Image
            source={{
              uri: "https://ladressepatrimoine.com/img/invierte.jpg",
            }}
            alt="Alternate Text"
            w={"100%"}
            borderRadius={10}
            h={{ base: 56, md: 96 }}
          />
        </Box>
        <VStack w={{ base: "100%", md: "60%" }}>
          <Box w="85%" alignSelf="center">
            <h3
              style={{
                fontWeight: "normal",
                fontFamily: "Graphik",
                textAlign: "justify",
                lineHeight: 1.4,
              }}
            >
              <strong>Exposición Global: </strong> Al asociarte con L'adresse
              Patrimoine, tu desarrollo obtendrá una exposición sin precedentes
              ante una audiencia global de inversores y compradores interesados
              en proyectos exclusivos y de alta calidad.
              <br /> <br />
              <strong>Promoción Profesional: </strong>Nuestro equipo
              especializado en marketing inmobiliario trabajará estrechamente
              contigo para crear campañas de promoción personalizadas que
              resalten los aspectos únicos y atractivos de tu desarrollo.
              <br /> <br />
              <strong>Acceso a Inversionistas Calificados:</strong> Conecta con
              una red selecta de inversionistas calificados y potenciales
              compradores que buscan oportunidades de inversión en el mercado
              inmobiliario.
            </h3>
          </Box>
        </VStack>
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <VStack w={{ base: "90%", md: "60%" }}>
          <Box w="100%" alignSelf="center">
            <h3
              style={{
                fontFamily: "Graphik",
                fontWeight: "normal",
                lineHeight: 1.5,
                textAlign: "justify",
                marginRight: 10,
              }}
            >
              <strong>Asesoramiento Estratégico:</strong> Recibe asesoramiento
              estratégico y orientación de nuestro equipo de expertos en bienes
              raíces, quienes te brindarán el apoyo necesario para optimizar la
              comercialización y venta de tu desarrollo.
              <br /> <br />
              <strong>Facilidad de Gestión:</strong> Simplifica el proceso de
              venta y comercialización de tu desarrollo al utilizar nuestra
              plataforma intuitiva y fácil de usar, diseñada para maximizar la
              eficiencia y la rentabilidad.
              <br /> <br />
              En L'adresse Patrimoine, estamos comprometidos a ayudarte a
              alcanzar tus objetivos comerciales y a hacer que tu desarrollo
              inmobiliario sea un éxito rotundo. Únete a nosotros y descubre
              cómo podemos convertir tu visión en una realidad tangible.
            </h3>
          </Box>
        </VStack>
        <Box w={{ base: "90%", md: "40%" }}>
          <Image
            source={{
              uri: "https://ladressepatrimoine.com/img/asesoramiento.jpg",
            }}
            alt="Alternate Text"
            borderRadius={10}
            w={"600px"}
            h={80}
          />
        </Box>
      </Stack>

      <ContactoFooter />
    </View>
  );
};

export default TuProyecto;
