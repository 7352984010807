import { Box, Heading, Text, View } from "native-base";
import React from "react";
import ContactoFooter from "../components/ContactoFooter";
import FotoPortada from "../components/global/FotoPortada";
import { primario } from "../helper/colors";

const SimuladorRentabilidad = () => {
  return (
    <View w="100%">
      <FotoPortada
        url="https://images.unsplash.com/photo-1429667947446-3c93a979b7e0"
        texto="Simulador de Rentabilildad"
      />

      <Box my={10} w="80%" alignSelf="center">
        <h1 style={{ fontFamily: "Baskerville" }}>
          Calcula la rentabilidad de tu inversión con nuestro simulador
        </h1>
      </Box>
      <Box
        w="80%"
        h={95}
        borderColor={primario}
        alignSelf="center"
        alignItems="center"
        justifyContent="Center"
        borderWidth={3}
        borderRadius={10}
      >
        <Text textAlign="center"> Calculadora en desarrrollo</Text>
      </Box>

      <ContactoFooter />
    </View>
  );
};
export default SimuladorRentabilidad;
