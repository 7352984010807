import React, { useState, useEffect } from "react";
import {
  VStack,
  FormControl,
  Input,
  Button,
  View,
  Text,
  Center,
  Icon,
  Heading,
  Alert,
  Stack,
  Select,
  HStack,
  CheckIcon,
  TextArea,
  Divider,
  InputGroup,
  InputLeftAddon,
  Image,
} from "native-base";
import { useUser } from "../../helper/UserContext";
import {
  FaRegEye,
  FaRegEyeSlash,
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";
import { MdAlternateEmail, MdPhone, MdOutlinePassword } from "react-icons/md";
import Loader from "../Loader";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router";
import { animateScroll as scroll } from "react-scroll";
import { MdAddPhotoAlternate, MdSaveAs } from "react-icons/md";

function InfoUserComponent() {
  const navigate = useNavigate();

  const {
    correo,
    apellido,

    nombre,
    userId,
    telefono,
    actualizaUser,
    tipo,
  } = useUser();
  const [name, setName] = useState(nombre);
  const [lastName, setLastName] = useState(apellido);
  const [phone, setPhone] = useState(telefono);

  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [lada, setLada] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertVisible(true);
  };

  const validateUserInfo = () => {
    if (name.trim() === "") {
      showAlert("Por favor, ingresa tu nombre.");
      return false;
    }
    if (lastName.trim() === "") {
      showAlert("Por favor, ingresa tu apellido.");
      return false;
    }
    if (phone.trim() === "") {
      showAlert("Por favor, ingresa tu teléfono.");
      return false;
    } else if (phone.trim().length < 10 || phone.trim().length > 13) {
      showAlert("El teléfono debe tener entre 10 y 13 números.");
      return false;
    }

    // Agrega aquí más validaciones si lo necesitas
    return true;
  };

  const validatePasswordChange = () => {
    if (currentPassword.trim() === "") {
      showAlert("Por favor, ingresa tu contraseña actual.");
      return false;
    }
    if (newPassword.trim() === "") {
      showAlert("Por favor, ingresa tu nueva contraseña.");
      return false;
    }
    if (confirmPassword.trim() === "") {
      showAlert("Por favor, confirma tu nueva contraseña.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      showAlert("La nueva contraseña y la confirmación no coinciden.");
      return false;
    }
    // Agrega aquí más validaciones si lo necesitas
    return true;
  };

  const handleChangePassword = async () => {
    if (validatePasswordChange()) {
      const dataLogin = new FormData();
      dataLogin.append("ID", userId);
      dataLogin.append("Correo", correo);
      dataLogin.append("PassAntigua", currentPassword);
      dataLogin.append("PassNueva", newPassword);
      const url = `${URL.BASE_URL}/actualizarPassword`;
      const options = {
        method: "POST",
        body: dataLogin,
      };
      const res = await fetchPost(url, options);
      console.log(res);

      setIsLoading(false);
      if (res.exito) {
        handleUpdate(name, lastName, phone);
        window.alert(res.mensaje);
      } else {
        window.alert(res.mensaje);
      }
    }
  };

  //contraseña icono mostrar
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show2, setShow2] = useState(false);
  const handleClick2 = () => setShow2(!show2);
  const [show3, setShow3] = useState(false);
  const handleClick3 = () => setShow3(!show3);

  const [user, setUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = (nombreUser, apellidoUser, telUser) => {
    // Lógica para el inicio de sesión
    actualizaUser(nombreUser, apellidoUser, telUser);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!validateUserInfo()) {
      setIsLoading(false); // Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("ID", userId);
    dataLogin.append("nombre", name);
    dataLogin.append("apellidoP", lastName);
    dataLogin.append("facebook", facebook);
    dataLogin.append("tiktok", tiktok);
    dataLogin.append("instagram", instagram);
    dataLogin.append("descripcion", descripcion);
    dataLogin.append("foto", imagen);
    dataLogin.append("lada", lada);
    dataLogin.append("telefono", phone);

    const url = `${URL.BASE_URL}/actualizarInfoUser`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log(res);

    setIsLoading(false);
    if (res.exito) {
      handleUpdate(name, lastName, phone);
      window.alert(res.mensaje);
    } else {
      window.alert(res.mensaje);
    }
  };

  const handlePerfil = () => {
    console.log("boton");
    navigate(`../userinformation/${userId}/${nombre}`);
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const [imagen, setImagen] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  const handlePortadaChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImagen(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    // Primero validar las credenciales

    const data = new FormData();
    data.append("ID", userId);
    data.append("nombre", nombre);
    const url = `${URL.BASE_URL}/verPerfil`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta login", res);
    if (res.exito) {
      setUser(res.datos);
      setDescripcion(res.datos.descripcion);
      setLastName(res.datos.apellidoP);
      setLada(res.datos.lada);
      setPhone(res.datos.telefono);
      setTiktok(res.datos.tiktok);
      setFacebook(res.datos.facebook);
      setInstagram(res.datos.instagram);
      setPreviewUrl(
        "https://ladressepatrimoine.com/back/public/uploads/profiles/" +
          res.datos.foto
      );
      setIsLoading(false);
    } else {
      window.alert("Error al buscar usuario, intente más tarde");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <View mt={24} w="100%">
      <Heading alignSelf="center">Mi información personal</Heading>
      <Text mb={3} alignSelf="center">
        Visualiza y edita tu información
      </Text>

      {isLoading ? (
        <Center my={20}>
          <Loader size={"md"} />
        </Center>
      ) : (
        <Stack
          alignSelf="center"
          justifyContent="space-between"
          my={5}
          direction={["column", "column", "row", "row"]}
          space={4}
          width="90%"
          mx="auto"
        >
          <VStack w={["90%", "90%", "40%", "40%"]}>
            <Text my={1} bold>
              Información personal
            </Text>
            <FormControl>
              <FormControl.Label>Nombre actual: {nombre}</FormControl.Label>
              <Input
                shadow={6}
                value={name}
                onChangeText={setName}
                placeholder={nombre}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<FaUserPen />} />
                  </Center>
                }
              />
            </FormControl>

            {tipo === "2" ? (
              <FormControl>
                <FormControl.Label>
                  Apellido actual: {lastName}
                </FormControl.Label>
                <Input
                  shadow={6}
                  value={lastName}
                  onChangeText={setLastName}
                  placeholder={apellido}
                  InputLeftElement={
                    <Center ml={2}>
                      <Icon as={<FaUserPen />} />
                    </Center>
                  }
                />
              </FormControl>
            ) : null}

            {/* Telefono */}
            <Divider m={4} />
            <HStack>
              <Center>
                <FaWhatsapp />
              </Center>
              <FormControl.Label>
                {" "}
                Teléfono actual: {lada + " " + phone}
              </FormControl.Label>
            </HStack>
            <HStack space={2}>
              <FormControl w={32}>
                <FormControl.Label>LADA</FormControl.Label>
                <Select
                  selectedValue={lada}
                  w={32}
                  accessibilityLabel="LADA"
                  placeholder="LADA"
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size="3" />,
                  }}
                  onValueChange={(itemValue) => setLada(itemValue)}
                >
                  <Select.Item label="MX (+52)" value="+52" />
                  <Select.Item label="US (+1)" value="+1" />
                  <Select.Item label="FR (+33)" value="+33" />
                  <Select.Item label="CA (+1)" value="+1" />
                </Select>
              </FormControl>
              <FormControl flex={1}>
                <FormControl.Label>Teléfono </FormControl.Label>
                <InputMask
                  mask="999 999 9999"
                  value={phone}
                  onChange={handleChangePhone}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      color={"#000"}
                      value={phone}
                      placeholder="Teléfono"
                      onChange={handleChangePhone}
                    />
                  )}
                </InputMask>
              </FormControl>
            </HStack>
            <Divider m={4} />

            {/* REDES SOCIALES */}
            <FormControl>
              <HStack space={3}>
                <Center ml={2}>
                  <Icon as={<FaFacebook />} />
                </Center>
                <FormControl.Label>
                  Facebook:{" "}
                  <a
                    href={" https://www.facebook.com/" + facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.facebook.com/{facebook}
                  </a>
                </FormControl.Label>
              </HStack>

              <InputGroup>
                <InputLeftAddon children={"https://www.facebook.com/"} />
                <Input
                  shadow={6}
                  value={facebook}
                  onChangeText={setFacebook}
                  placeholder={"Facebook"}
                />
              </InputGroup>
            </FormControl>
            {/* instagram */}
            <FormControl>
              <HStack space={3}>
                <Center ml={2}>
                  <Icon as={<FaInstagram />} />
                </Center>
                <FormControl.Label>
                  Instagram:{" "}
                  <a
                    href={" https://www.instagram.com/" + instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.instagram.com/{instagram}
                  </a>
                </FormControl.Label>
              </HStack>
              <InputGroup>
                <InputLeftAddon children={"https://www.instagram.com/"} />
                <Input
                  shadow={6}
                  value={instagram}
                  onChangeText={setInstagram}
                  placeholder={"Instagram"}
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <HStack space={3}>
                <Center ml={2}>
                  <Icon as={<FaTiktok />} />
                </Center>
                <FormControl.Label>
                  TikTok:{" "}
                  <a
                    href={" https://www.tiktok.com/" + tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.tiktok.com/{tiktok}
                  </a>
                </FormControl.Label>
              </HStack>
              <InputGroup flex={1}>
                <InputLeftAddon children={"https://www.tiktok.com/"} />
                <Input
                  shadow={6}
                  value={tiktok}
                  onChangeText={setTiktok}
                  placeholder={"TikTok"}
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <HStack space={4}>
                <Center ml={2}>
                  <Icon as={<FaUserPen />} />
                </Center>
                <FormControl.Label>
                  Descripcion: {descripcion}
                </FormControl.Label>
              </HStack>
              <TextArea
                shadow={6}
                h={40}
                value={descripcion}
                onChangeText={setDescripcion}
                placeholder="Descripcion"
              />
            </FormControl>

            <FormControl>
              <FormControl.Label>Correo Electrónico</FormControl.Label>
              <Input
                shadow={6}
                bg="#d1d1d1"
                _focus={{ backgroundColor: "#d1d1d1" }}
                value={correo}
                isReadOnly={true}
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdAlternateEmail />} />
                  </Center>
                }
              />
            </FormControl>
            {isAlertVisible && (
              <Alert w="100%" status="error">
                <Alert.Icon />
                <Text>{alertMessage}</Text>
              </Alert>
            )}

            {isLoading ? (
              <Center my={10}>
                <Loader size="md" color="teal.500" />
              </Center>
            ) : (
              <Button
                shadow={6}
                w="70%"
                alignSelf="center"
                my={4}
                onPress={handleSubmit}
              >
                Actualizar Información
              </Button>
            )}

            <Button
              shadow={6}
              w="70%"
              alignSelf="center"
              my={4}
              onPress={handlePerfil}
            >
              Ver perfil público
            </Button>
          </VStack>
          {/* Cambiar contraseña */}
          <VStack w={["90%", "90%", "40%", "40%"]}>
            <FormControl
              w={80}
              mr={12}
              maxH={96}
              minH={28}
              my={5}
              borderWidth={1}
              borderColor={"muted.300"}
              borderRadius={10}
              shadow={4}
            >
              <Text
                bold
                fontSize="lg"
                color="muted.600"
                my={3}
                alignSelf="center"
              >
                Foto de Perfil:
              </Text>
              <input
                type="file"
                onChange={handlePortadaChange}
                style={{ display: "none" }}
                id="file-input-portada"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={1}
                  source={{ uri: previewUrl }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-portada"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  w={64}
                  h={12}
                  mb={4}
                  colorScheme={"blue"}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto de Perfil
                </Button>
              </label>
            </FormControl>

            <Text my={1} bold alignSelf="center">
              Cambiar contraseña
            </Text>
            <FormControl>
              <FormControl.Label>Ingresa Contraseña Actual</FormControl.Label>
              <Input
                shadow={6}
                value={currentPassword}
                onChangeText={setCurrentPassword}
                type={show ? "text" : "password"}
                InputRightElement={
                  <Button
                    ml={1}
                    variant="link"
                    roundedLeft={0}
                    roundedRight="md"
                    onPress={handleClick}
                  >
                    {show ? <FaRegEyeSlash /> : <FaRegEye />}
                  </Button>
                }
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdOutlinePassword />} />
                  </Center>
                }
              />
            </FormControl>

            <FormControl>
              <FormControl.Label>Nueva Contraseña</FormControl.Label>
              <Input
                shadow={6}
                value={newPassword}
                onChangeText={setNewPassword}
                type={show2 ? "text" : "password"}
                InputRightElement={
                  <Button
                    ml={1}
                    variant="link"
                    roundedLeft={0}
                    roundedRight="md"
                    onPress={handleClick2}
                  >
                    {show2 ? <FaRegEyeSlash /> : <FaRegEye />}
                  </Button>
                }
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdOutlinePassword />} />
                  </Center>
                }
              />
            </FormControl>

            <FormControl>
              <FormControl.Label>Confirmar Contraseña</FormControl.Label>
              <Input
                shadow={6}
                value={confirmPassword}
                onChangeText={setConfirmPassword}
                type={show3 ? "text" : "password"}
                InputRightElement={
                  <Button
                    ml={1}
                    variant="link"
                    roundedLeft={0}
                    roundedRight="md"
                    onPress={handleClick3}
                  >
                    {show3 ? <FaRegEyeSlash /> : <FaRegEye />}
                  </Button>
                }
                InputLeftElement={
                  <Center ml={2}>
                    <Icon as={<MdOutlinePassword />} />
                  </Center>
                }
              />
            </FormControl>

            <Button
              mb={10}
              w="70%"
              alignSelf="center"
              my={4}
              colorScheme="danger"
              shadow={6}
              onPress={handleChangePassword}
            >
              Cambiar Contraseña
            </Button>
          </VStack>
        </Stack>
      )}
    </View>
  );
}

export default InfoUserComponent;
