import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Checkbox,
  CheckIcon,
  View,
  Input,
  Text,
  Image,
  FormControl,
  Select,
  useToast,
  Box,
  Center,
  HStack,
  VStack,
  Stack,
  TextArea,
} from "native-base";
import Loader from "../Loader";
import fetchPost from "../../helper/fetchPost";
import URL from "../../helper/baseURL";
import MapComponent from "./MapComponent";
import { MdAddPhotoAlternate, MdSaveAs } from "react-icons/md";
import { useUser } from "../../helper/UserContext";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { useParams } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FaRegFilePdf } from "react-icons/fa6";
import { IconContext } from "react-icons";

const EditarCrudComponent = () => {
  const { userId } = useUser();
  const { id_inmueble, id_user } = useParams();
  const [IdUser, setIdUser] = useState(userId);

  useEffect(() => {
    console.log("id_user: ", id_user);
    console.log("id_inmueble: ", id_inmueble);
  }, []);
  const URL_prev = "https://ladressepatrimoine.com/back/public/uploads/";

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("userID");
      if (value !== null) {
        console.log(value);
        setIdUser(value);
        getInmueble(value);
      }
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    console.log("editar iduser: ", userId);
    if (userId === null) {
      getData();
    }

    console.log("editar idInmueble: ", id_inmueble);
    getInmueble(userId);
  }, []);

  const getInmueble = async (IDusuario) => {
    const data = new FormData();
    data.append("id_user", IDusuario);
    data.append("id_inmueble", id_inmueble);
    const url = `${URL.BASE_URL}detalleInmuebleAdmin`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);

    if (res && res.status === true) {
      setTitulo(res.Inmueble.nombre);
      setDescripcion(res.Inmueble.descripcion);
      setArea(res.Inmueble.area);
      setPrecio(res.Inmueble.precio);
      setCategoria(res.Inmueble.categoria);
      setHabitaciones(res.Inmueble.habitaciones);
      setWc(res.Inmueble.wc);
      setUnidades(res.Inmueble.unidades);
      setRentabilidad(res.Inmueble.rentabilidad);

      setComision(res.Inmueble.comision);
      setTiempoEntrega(res.Inmueble.tiempoEntrega);
      setAntiguedad(res.Inmueble.antiguedad);
      setEstado(res.Inmueble.estado);
      setMunicipio(res.Inmueble.municipio);
      setUbicacion(res.Inmueble.ubicacion);
      setLatitud(res.Inmueble.latitud);
      setLongitud(res.Inmueble.longitud);
      setGoogleMapLink(res.Inmueble.mapa);
      setAmenidades(res.Inmueble.amenidades.split(",")); // Convertir la cadena de amenidades en un array
      setPreviewBrochure(res.Inmueble.brochure);
      // setImagen(res.Inmueble.fotoportada);
      setPreviewPlanos(res.Inmueble.planos);
      setPreviewLegal(res.Inmueble.legal);
      setPreviewUrl(URL_prev + res.Inmueble.fotoportada);
      setPreviewUrl1(URL_prev + res.Inmueble.foto1);
      setPreviewUrl2(URL_prev + res.Inmueble.foto2);
      setPreviewUrl3(URL_prev + res.Inmueble.foto3);
      setPreviewUrl4(URL_prev + res.Inmueble.foto4);
    } else {
    }
  };

  const toast = useToast();

  const handleMapLinkChange = (newLink) => {
    setGoogleMapLink(newLink);
  };

  const handleLatitudChange = (lat) => {
    setLatitud(lat);
  };
  const handleLongitudChange = (long) => {
    setLongitud(long);
  };

  // Aquí iría la lógica para manejar los estados y la subida de imágenes
  const [isLoading, setIsLoading] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [area, setArea] = useState("");
  const [precio, setPrecio] = useState("");
  const [categoria, setCategoria] = useState("");
  const [habitaciones, setHabitaciones] = useState(0);
  const [wc, setWc] = useState(0);
  const [unidades, setUnidades] = useState("");
  const [rentabilidad, setRentabilidad] = useState("");
  const [comision, setComision] = useState("");
  const [tiempoEntrega, setTiempoEntrega] = useState("");
  const [antiguedad, setAntiguedad] = useState("");
  const [pais, setPais] = useState(null);
  const [estado, setEstado] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [ubicacion, setUbicacion] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [amenidades, setAmenidades] = useState([]);
  const [imagen, setImagen] = useState(null);
  const [planos, setPlanos] = useState(null);
  const [previewPlanos, setPreviewPlanos] = useState(null);
  const [brochure, setBrochure] = useState(null);
  const [previewBrochure, setPreviewBrochure] = useState(null);
  //legal
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewLegal, setPreviewLegal] = useState(null);
  const [foto1, setFoto1] = useState(null);
  const [foto2, setFoto2] = useState(null);
  const [foto3, setFoto3] = useState(null);
  const [foto4, setFoto4] = useState(null);

  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewUrl1, setPreviewUrl1] = useState(null);
  const [previewUrl2, setPreviewUrl2] = useState(null);
  const [previewUrl3, setPreviewUrl3] = useState(null);
  const [previewUrl4, setPreviewUrl4] = useState(null);

  useEffect(() => {
    console.log("amenidades: ", amenidades);
  }, [amenidades]);

  const handleFoto1Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto1(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl1(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFoto2Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto2(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl2(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFoto3Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto3(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl3(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFoto4Change = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFoto4(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl4(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handlePortadaChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImagen(file);

      // Crear una URL para la previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  // FUNCION EDITAR INMUEBLE
  const EditarInmueble = async () => {
    // if (!validarCampos()) {
    //   return;
    // }

    setIsLoading(true);

    const data = new FormData();
    data.append("id_user", userId);
    data.append("id_inmueble", id_inmueble);
    data.append("status", 1);
    data.append("nombre", titulo);
    data.append("descripcion", descripcion);
    data.append("precio", precio);
    data.append("categoria", categoria);
    data.append("habitaciones", habitaciones);
    data.append("wc", wc);
    data.append("area", area);
    data.append("unidades", unidades);
    data.append("rentabilidad", rentabilidad);
    data.append("comision", comision);
    data.append("tiempoEntrega", tiempoEntrega);
    data.append("antiguedad", antiguedad);
    data.append("pais", pais);
    data.append("estado", 1);
    data.append("municipio", 1);
    data.append("ubicacion", ubicacion);
    data.append("mapa", googleMapLink);
    data.append("latitud", latitud);
    data.append("longitud", longitud);
    data.append("amenidades", amenidades);
    data.append("brochure", brochure);
    data.append("fotoportada", imagen);
    data.append("planos", planos);
    data.append("legal", selectedFile);
    data.append("foto1", foto1);
    data.append("foto2", foto2);
    data.append("foto3", foto3);
    data.append("foto4", foto4);

    // ... Agregar otras imágenes y campos si es necesario

    const url = `${URL.BASE_URL}editarInmueble`; // Asegúrate de que la URL sea correcta
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta", res);
    console.log(url);

    if (res && res.resultado) {
      toast.show({
        duration: 10000,
        render: () => {
          return (
            <Box
              bg="emerald.500"
              px="10"
              py="10"
              rounded="sm"
              mb={5}
              fontSize={"2xl"}
            >
              <Text fontSize={"xl"} color={"#fff"} textAlign={"center"} bold>
                Se editó el inmueble con éxito.
              </Text>
            </Box>
          );
        },
      });
    } else {
      setIsLoading(false);
      toast.show({
        duration: 10000,
        render: () => {
          return (
            <Box
              bg="danger.500"
              px="3"
              py="5"
              rounded="sm"
              mb={5}
              fontSize={"lg"}
            >
              <Text fontSize={"xl"} color={"#fff"} textAlign={"center"} bold>
                Hubo un error, intentalo más tarde.
              </Text>
            </Box>
          );
        },
      });
    }
    setIsLoading(false);
  };

  const validarCampos = () => {
    let camposFaltantes = [];
    if (!titulo) camposFaltantes.push("título");

    if (!ubicacion) camposFaltantes.push("ubicación");
    if (!precio) camposFaltantes.push("precio");
    if (!categoria) camposFaltantes.push("categoría");
    // if (!imagen) camposFaltantes.push("imagen");

    if (camposFaltantes.length > 0) {
      let mensaje =
        "Por favor, completa los siguientes campos requeridos: " +
        camposFaltantes.join(", ") +
        ".";
      toast.show({
        title: "Campos Faltantes",
        description: mensaje,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return false;
    }

    toast.show({
      title: "Éxito",
      description: "Todos los campos están completos.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    return true;
  };

  // manejo de archivos
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const formatBytes = (bytes) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  };

  const handlePlanosChange = (e) => {
    const file = e.target.files[0];
    setPlanos(file);
  };
  const handleBrochureChange = (e) => {
    const file = e.target.files[0];
    setBrochure(file);
  };

  //fin manejo archivos

  //handle de habitaciones

  const handleIncrease = () => {
    setHabitaciones(habitaciones + 1);
  };

  const handleDecrease = () => {
    if (habitaciones > 0) {
      setHabitaciones(habitaciones - 1);
    }
  };

  //handle baños
  const handleIncreaseWC = () => {
    setWc(wc + 1);
  };

  const handleDecreaseWC = () => {
    if (wc > 0) {
      setWc(wc - 1);
    }
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };
  //Handle estados
  const getEstados = async () => {
    const url = `${URL.BASE_URL}/verEstados`; // Asegúrate de que la URL sea correcta
    const options = {
      method: "POST",
    };
    const res = await fetchPost(url, options);

    setEstados(res);
  };

  useEffect(() => {
    getEstados();
  }, []);

  //Handle municipios
  const getMunicipios = async (id) => {
    const data = new FormData();
    data.append("ID", id);
    const url = `${URL.BASE_URL}/verMunicipios`; // Asegúrate de que la URL sea correcta
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);

    setMunicipios(res);
  };

  useEffect(() => {
    getMunicipios(estado);
  }, [estado]);

  const handleViewBrouche = () => {
    window.open(previewBrochure ? URL_prev + previewBrochure : null, "_blank");
  };

  const handleViewLegal = () => {
    window.open(previewBrochure ? URL_prev + previewLegal : null, "_blank");
  };

  const handleViewPlanos = () => {
    window.open(previewBrochure ? URL_prev + previewPlanos : null, "_blank");
  };

  const handlePais = async (idPais) => {
    setPais(idPais);

    const dataEstados = new FormData();
    dataEstados.append("country_id", idPais);

    const url = `${URL.BASE_URL}/getEstadosPorPais`;
    const options = {
      method: "POST",
      body: dataEstados,
    };
    const res = await fetchPost(url, options);
    console.log("res", res.data);

    //Falta manejar el resultado
    if (res.data) {
      setEstados(res.data);
    }
  };

  const handleEstado = async (idEstado) => {
    setEstado(idEstado);

    const dataEstados = new FormData();
    dataEstados.append("state_id", idEstado);

    const url = `${URL.BASE_URL}/getCiudadesPorEstado`;
    const options = {
      method: "POST",
      body: dataEstados,
    };
    const res = await fetchPost(url, options);

    //Falta manejar el resultado
    if (res.data) {
      console.log("res municipios", res.data);

      setMunicipios(res.data);
    }
  };

  const PaisSelect = () => {
    return (
      <Stack
        direction={{ base: "column", md: "row" }}
        alignSelf={"center"}
        w="80%"
        space={5}
      >
        <FormControl flex={1} isRequired>
          <FormControl.Label>Selecciona tu país</FormControl.Label>
          <Select
            minWidth="200"
            accessibilityLabel="País"
            placeholder="País"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
            selectedValue={pais}
            onValueChange={(itemValue) => handlePais(itemValue)}
          >
            <Select.Item label="México" value="141" />
            <Select.Item label="Estados Unidos" value="236" />
            <Select.Item label="Francia" value="76" />
            <Select.Item label="España" value="209" />
          </Select>
        </FormControl>

        <FormControl flex={1} display={estados.length === 0 ? "none" : "flex"}>
          <FormControl.Label>Selecciona tu Estado</FormControl.Label>
          <Select
            minWidth="200"
            accessibilityLabel="Estado"
            placeholder="Estado"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
            selectedValue={estado}
            onValueChange={(itemValue) => handleEstado(itemValue)}
          >
            {estados === null
              ? null
              : estados.map((estado) => (
                  <Select.Item
                    key={estado.state_id}
                    label={estado.state_name}
                    value={estado.state_id}
                  />
                ))}
          </Select>
        </FormControl>

        <FormControl
          flex={1}
          isRequired
          display={municipios === null ? "none" : "flex"}
        >
          <FormControl.Label>Selecciona tu localidad</FormControl.Label>
          <Select
            minWidth="200"
            accessibilityLabel="Localidad"
            placeholder="Localidad"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
            selectedValue={municipio}
            onValueChange={(itemValue) => setMunicipio(itemValue)}
          >
            {municipios === null
              ? null
              : municipios.map((municipio) => (
                  <Select.Item
                    key={municipio.city_id}
                    label={municipio.city_name}
                    value={municipio.city_id}
                  />
                ))}
          </Select>
        </FormControl>
      </Stack>
    );
  };

  return (
    <View w="100%" mx="auto" mb={5}>
      <>
        <PaisSelect />
      </>
      <Center w="90%" alignSelf="center">
        <HStack>
          <FormControl isRequired flex={1} mx={3}>
            <FormControl.Label>Titulo</FormControl.Label>
            <Input
              placeholder="Titulo del inmueble"
              onChangeText={(val) => setTitulo(val)}
              value={titulo}
            />
          </FormControl>
          {/* Área de inmueble */}
          <FormControl isRequired flex={1} mx={3}>
            <FormControl.Label>Área del inmueble (m2)</FormControl.Label>
            <Input
              placeholder="Área en metros cuadrados"
              onChangeText={(val) => setArea(val)}
              value={area}
            />
          </FormControl>
        </HStack>

        <div style={stackContainerStyle}>
          {/* habitaciones */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>Habitaciones: </FormControl.Label>
            <HStack space={2}>
              <Input
                placeholder="Número de habitaciones"
                w={24}
                onChangeText={(val) => setHabitaciones(val)}
                value={habitaciones}
                isReadOnly={true}
              />
              <VStack alignSelf={"flex-end"}>
                <button onClick={handleIncrease}>+</button>
                <button onClick={handleDecrease}>-</button>
              </VStack>
            </HStack>
          </FormControl>

          {/* UNIDADES DISPONIBLES */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>Unidades disponibles: </FormControl.Label>

            <Input
              placeholder="Unidades disponibles"
              flex={1}
              onChangeText={(val) => setUnidades(val)}
              value={unidades}
            />
          </FormControl>

          {/* RENTBILIDAD */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>Rentabilidad: </FormControl.Label>

            <Input
              placeholder="Rentabilidad"
              flex={1}
              onChangeText={(val) => setRentabilidad(val)}
              value={rentabilidad}
            />
          </FormControl>

          {/* COMISION */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label> % Comisión: </FormControl.Label>
            <Input
              placeholder="Comisión"
              flex={1}
              onChangeText={(val) => setComision(val)}
              value={comision}
            />
          </FormControl>

          {/* BAÑOS */}
          <FormControl
            isRequired
            p={2}
            w={40}
            m={2}
            borderWidth={1}
            borderRadius={10}
            borderColor="muted.300"
            shadow={3}
          >
            <FormControl.Label>Baños: </FormControl.Label>
            <HStack space={2}>
              <Input
                placeholder="Número de baños"
                w={24}
                onChangeText={(val) => setWc(val)}
                value={wc}
                isReadOnly={true}
              />
              <VStack alignSelf={"flex-end"}>
                <button onClick={handleIncreaseWC}>+</button>
                <button onClick={handleDecreaseWC}>-</button>
              </VStack>
            </HStack>
          </FormControl>
        </div>
      </Center>

      {/* Mapa */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="100%"
        space={4}
        alignSelf="center"
      >
        <VStack>
          <FormControl
            isRequired
            w={"450px"}
            mx={5}
            borderWidth={1}
            borderColor="muted.300"
            shadow={4}
            borderRadius={10}
            alignSelf={"center"}
          >
            <Text
              alignSelf="center"
              fontSize="xl"
              color="muted.500"
              my={4}
              fontWeight="bold"
            >
              Selecciona la ubicación en el mapa:
            </Text>
            <div style={{ alignSelf: "center" }}>
              <MapComponent
                onMapLinkChange={handleMapLinkChange}
                onLatitudChange={handleLatitudChange}
                onLongitudChange={handleLongitudChange}
              />
              <Center px={2}>
                <p>
                  <strong> Enlace de Google Maps:</strong> {googleMapLink}
                </p>
              </Center>
            </div>
          </FormControl>
          {/* Archivos brochure */}
          <VStack
            px={5}
            mx={5}
            justifyContent="center"
            alignItems="center"
            my={5}
            h={40}
            borderWidth={1}
            borderRadius={10}
            shadow={4}
            borderColor="muted.300"
          >
            <Text fontSize="md" bold>
              Sube el brochure de la propiedad:
            </Text>
            <div>
              <input
                type="file"
                accept=".pdf"
                onChange={handleBrochureChange}
              />
              {brochure ? (
                <div>
                  <p>Nombre del archivo: {brochure.name}</p>
                  <p>Tamaño del archivo: {formatBytes(brochure.size)}</p>
                </div>
              ) : (
                <div>
                  <p>Archivo actual:</p>
                  {previewBrochure ? (
                    <Button
                      onPress={handleViewBrouche}
                      leftIcon={
                        <IconContext.Provider
                          value={{
                            color: "white",
                            size: "30px",
                          }}
                        >
                          <Icon as={FaRegFilePdf} name="cloud-upload-outline" />
                        </IconContext.Provider>
                      }
                    >
                      Ver Brouche
                    </Button>
                  ) : (
                    <p>No hay archivo disponible</p>
                  )}
                </div>
              )}
            </div>
          </VStack>
        </VStack>

        <VStack flex={1} mr={10}>
          <FormControl isRequired>
            <FormControl.Label>Descripción</FormControl.Label>
            <TextArea
              h={40}
              placeholder="Descripción del inmueble"
              onChangeText={(val) => setDescripcion(val)}
              value={descripcion}
            />
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label>Ubicación</FormControl.Label>
            <Input
              placeholder="Ubicación del inmueble"
              onChangeText={(val) => setUbicacion(val)}
              value={ubicacion}
            />
          </FormControl>

          <FormControl isRequired flex={1} alignSelf="center">
            <FormControl.Label>Tiempo de entrega:</FormControl.Label>
            <Input
              placeholder="Tiempo de entrega"
              onChangeText={(val) => setTiempoEntrega(val)}
              value={tiempoEntrega}
            />
          </FormControl>

          <FormControl isRequired flex={1}>
            <FormControl.Label>Antigüedad</FormControl.Label>
            <Input
              placeholder="Antigüedad"
              onChangeText={(val) => setAntiguedad(val)}
              value={antiguedad}
            />
          </FormControl>

          <FormControl isRequired flex={1}>
            <FormControl.Label>Precio</FormControl.Label>
            <Input
              placeholder="Precio del inmueble"
              keyboardType="numeric"
              onChangeText={(val) => setPrecio(val)}
              value={precio}
            />
          </FormControl>

          <FormControl isRequired flex={1}>
            <FormControl.Label>Categoría</FormControl.Label>
            <Select
              placeholder="Selecciona una categoría"
              onValueChange={(itemValue) => setCategoria(itemValue)}
            >
              <Select.Item label="Frente a la playa" value="1" />
              <Select.Item label="Habitaciones" value="2" />
              <Select.Item label="Casas particulares" value="3" />
              <Select.Item label="Albercas increibles" value="4" />
              <Select.Item label="Adaptados" value="5" />
            </Select>
          </FormControl>

          {/* Planos */}
          <VStack
            px={5}
            justifyContent="center"
            alignItems="center"
            my={5}
            h={40}
            borderWidth={1}
            borderRadius={10}
            shadow={4}
            borderColor="muted.300"
          >
            <Text fontSize="md" bold>
              Sube los planos de la propiedad:
            </Text>

            <div>
              <input type="file" accept=".pdf" onChange={handlePlanosChange} />
              {planos ? (
                <div>
                  <p>Nombre del archivo: {planos.name}</p>
                  <p>Tamaño del archivo: {formatBytes(planos.size)} MB</p>
                </div>
              ) : (
                <div>
                  <p>Archivo actual:</p>
                  {previewPlanos ? (
                    <Button
                      onPress={handleViewPlanos}
                      leftIcon={
                        <IconContext.Provider
                          value={{
                            color: "white",
                            size: "30px",
                          }}
                        >
                          <Icon as={FaRegFilePdf} name="cloud-upload-outline" />
                        </IconContext.Provider>
                      }
                    >
                      Ver Planos
                    </Button>
                  ) : (
                    <p>No hay archivo disponible</p>
                  )}
                </div>
              )}
            </div>
          </VStack>

          {/* DOCUMENTOS LEGALES */}
          <VStack
            h={40}
            px={5}
            justifyContent="center"
            alignItems="center"
            my={5}
            py={3}
            borderWidth={1}
            borderRadius={10}
            shadow={4}
            borderColor="muted.300"
          >
            <Text fontSize="lg" bold>
              Sube documento legal de la propiedad:
            </Text>

            <div>
              <input type="file" accept=".pdf" onChange={handleFileChange} />
              {selectedFile ? (
                <div>
                  <p>Nombre del archivo: {selectedFile.name}</p>
                  <p>Tamaño del archivo: {formatBytes(selectedFile.size)} MB</p>
                </div>
              ) : (
                <div>
                  <p>Archivo actual:</p>
                  {previewLegal ? (
                    <Button
                      onPress={handleViewLegal}
                      leftIcon={
                        <IconContext.Provider
                          value={{
                            color: "white",
                            size: "30px",
                          }}
                        >
                          <Icon as={FaRegFilePdf} name="cloud-upload-outline" />
                        </IconContext.Provider>
                      }
                    >
                      Ver Documentos legales
                    </Button>
                  ) : (
                    <p>No hay archivo disponible</p>
                  )}
                </div>
              )}
            </div>
          </VStack>
        </VStack>
      </Stack>

      <HStack alignSelf={"center"} flex={1}>
        {/* AMENIDADES */}
        <FormControl
          isRequired
          p={2}
          w={56}
          h={40}
          m={2}
          mt={4}
          borderWidth={1}
          borderRadius={10}
          borderColor="muted.300"
          shadow={3}
        >
          <FormControl.Label>Amenidades: </FormControl.Label>

          <Checkbox.Group
            onChange={setAmenidades}
            value={amenidades}
            accessibilityLabel="Elige amenidades"
          >
            <Checkbox value="Piscina" my={2}>
              Piscina
            </Checkbox>
            <Checkbox value="Estacionamiento">Estacionamiento</Checkbox>
            <Checkbox value="Vigilancia">Vigilancia</Checkbox>
            <Checkbox value="Concierge">Concierge</Checkbox>
            <Checkbox value="Seguridad 24/7">Seguridad 24/7</Checkbox>
            <Checkbox value="Gimnasio">Gimnasio</Checkbox>
            <Checkbox value="Spa">Spa</Checkbox>
            <Checkbox value="Sala de juegos">Sala de juegos</Checkbox>
            <Checkbox value="Rooftop">Rooftop</Checkbox>
            <Checkbox value="Zona de yoga">Zona de yoga</Checkbox>
            <Checkbox value="Coworking">Coworking</Checkbox>
            <Checkbox value="Cine">Cine</Checkbox>
          </Checkbox.Group>
        </FormControl>
        {/* FOTO DE PORTADA */}
        <FormControl
          w={80}
          mr={12}
          maxH={96}
          minH={28}
          my={5}
          borderWidth={1}
          borderColor={"muted.300"}
          borderRadius={10}
          shadow={4}
        >
          <Text bold fontSize="lg" color="muted.600" my={3} alignSelf="center">
            Foto Portada:
          </Text>
          <input
            type="file"
            onChange={handlePortadaChange}
            style={{ display: "none" }}
            id="file-input-portada"
            accept="image/*" // Asegúrate de aceptar solo imágenes
          />
          {previewUrl && (
            <Image
              borderRadius={10}
              alignSelf="center"
              mb={1}
              source={{ uri: previewUrl }}
              alt="Previsualización de la imagen"
              size="2xl" // Puedes ajustar el tamaño según tus necesidades
            />
          )}
          <label
            htmlFor="file-input-portada"
            style={{ alignSelf: "center", marginTop: "11px" }}
          >
            <Button
              w={64}
              h={12}
              mb={4}
              colorScheme={"blue"}
              as="span"
              leftIcon={
                <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
              }
            >
              Seleccionar Foto de Portada
            </Button>
          </label>
        </FormControl>
      </HStack>

      {/* IMAGENES */}
      <Stack
        w={"90%"}
        mx={20}
        direction={{ base: "column", lg: "row" }}
        justifyContent="center"
      >
        {/* IMAGENES */}
        <FormControl
          w={"40rem"}
          mx={4}
          my={5}
          borderWidth={1}
          borderColor={"muted.300"}
          borderRadius={10}
          shadow={4}
          alignSelf="center"
        >
          <Text bold fontSize="lg" color="muted.600" my={2} alignSelf="center">
            Fotos de galería:
          </Text>

          <Stack
            w="90%"
            direction={{ base: "column", md: "row" }}
            justifyContent="center"
            alingItems="center"
            space={10}
            alignSelf={"center"}
          >
            <VStack>
              <Text
                bold
                fontSize="lg"
                color="muted.600"
                my={3}
                alignSelf="center"
              >
                Foto 1:
              </Text>
              <input
                type="file"
                onChange={handleFoto1Change}
                style={{ display: "none" }}
                id="file-input-foto1"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl1 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl1 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto1"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  w={64}
                  colorScheme={"blue"}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 1
                </Button>
              </label>
            </VStack>

            <VStack>
              <Text
                bold
                fontSize="lg"
                color="muted.600"
                my={3}
                alignSelf="center"
              >
                Foto 2:
              </Text>
              <input
                type="file"
                onChange={handleFoto2Change}
                style={{ display: "none" }}
                id="file-input-foto2"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl2 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl2 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto2"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  w={64}
                  colorScheme={"blue"}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 2
                </Button>
              </label>
            </VStack>
          </Stack>

          <HStack justifyContent="center" alingItems="center" space={10} pb={5}>
            <VStack>
              <Text
                bold
                fontSize="lg"
                color="muted.600"
                my={3}
                alignSelf="center"
              >
                Foto 3:
              </Text>
              <input
                type="file"
                onChange={handleFoto3Change}
                style={{ display: "none" }}
                id="file-input-foto3"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl3 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl3 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto3"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  w={64}
                  colorScheme={"blue"}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 3
                </Button>
              </label>
            </VStack>

            <VStack>
              <Text
                bold
                fontSize="lg"
                color="muted.600"
                my={3}
                alignSelf="center"
              >
                Foto 4:
              </Text>
              <input
                type="file"
                onChange={handleFoto4Change}
                style={{ display: "none" }}
                id="file-input-foto4"
                accept="image/*" // Asegúrate de aceptar solo imágenes
              />
              {previewUrl4 && (
                <Image
                  borderRadius={10}
                  alignSelf="center"
                  mb={3}
                  source={{ uri: previewUrl4 }}
                  alt="Previsualización de la imagen"
                  size="2xl" // Puedes ajustar el tamaño según tus necesidades
                />
              )}
              <label
                htmlFor="file-input-foto4"
                style={{ alignSelf: "center", marginTop: "11px" }}
              >
                <Button
                  w={64}
                  colorScheme={"blue"}
                  as="span"
                  leftIcon={
                    <Icon as={<MdAddPhotoAlternate color="#fff" size={32} />} />
                  }
                >
                  Seleccionar Foto 4
                </Button>
              </label>
            </VStack>
          </HStack>
        </FormControl>
      </Stack>

      {isLoading ? (
        <Box flex={1} my={12} alignSelf="center">
          <Loader size="xl" color="#04324c" />
        </Box>
      ) : (
        <Button
          mt={5}
          alignSelf="center"
          w={80}
          py={4}
          colorScheme="success"
          _text={{ fontWeight: "bold", fontSize: "lg" }}
          leftIcon={<Icon as={<MdSaveAs color="#fff" size={24} />} />}
          onPress={() => EditarInmueble()}
        >
          Guardar Inmueble
        </Button>
      )}
    </View>
  );
};

export default EditarCrudComponent;
