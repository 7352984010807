import React, { useRef } from "react";
import Slider from "react-slick";

import { Button, HStack, Text, useBreakpointValue } from "native-base";
import InmuebleComponent2 from "../InmuebleComponent2";
import { FcPrevious, FcNext } from "react-icons/fc";
import { primario } from "../../helper/colors";

const datosEstaticos = [
    {
        ID: '1',
        fotoportada: 'BELEHU.webp',
        nombre: 'BELEHU',
        ubicacion: 'Tulum',
        precio: '2,800,000',
        rooms: '2',
        bath: '1',
        guest: '2',
        tipo: 'Departamento'
    },
    {
        ID: '2',
        fotoportada: 'oceantulum.jpg',
        nombre: 'Ocean Tulum',
        ubicacion: 'Cancún',
        precio: '2,300,000',
        rooms: '2',
        bath: '1',
        guest: '2',
        tipo: 'Lotes residenciales'
    },

];

const InmuebleSlider = () => {
    const sliderRef = useRef(null);
    const slidesToShow = useBreakpointValue({
        base: 1, // por defecto, mostrar 1 slide
        md: 1,   // en pantallas md y superiores, mostrar 1 slide
        lg: 2    // en pantallas lg y superiores, mostrar 2 slides
    });

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
    };

    return (
        <>
            <HStack style={{ textAlign: "center", marginBottom: "20px" }} justifyContent="space-between">
                <Text bold fontSize="4xl" color="muted.700" fontFamily={"Graphik"} textAlign={"center"}>
                    Nuestros últimos programas{" "}
                    <Text fontWeight={"normal"}>inmobiliarios</Text>

                </Text>
                <HStack space={4} m={3} justifyContent={"center"} alignItems={"center"}>
                    <Button variant="ghost" onPress={previous} h={12} w={12} borderWidth={1}
                        borderRadius={100} justifyContent={"center"} alignItems={"center"} borderColor={primario}>
                        <FcPrevious size="1.8rem" />
                    </Button>
                    <Button variant="ghost" onPress={next}
                        h={12} w={12} borderWidth={1}
                        borderRadius={100} justifyContent={"center"}
                        alignItems={"center"} borderColor={primario}>
                        <FcNext size="1.8rem" />
                    </Button>
                </HStack>

            </HStack>
            <div className="slider-container">
                <Slider
                    ref={sliderRef}
                    {...settings}
                >
                    {datosEstaticos.map((item) => (
                        <InmuebleComponent2
                            key={item.ID}
                            imageUri={item.fotoportada}
                            titulo={item.nombre}
                            lugar={item.ubicacion}
                            precio={item.precio}
                            id={item.ID}
                            rooms={item.rooms}
                            bath={item.bath}
                            guest={item.guest}
                            tipo={item.tipo}
                        />
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default InmuebleSlider;
