import { Box, Pressable, Text, ZStack, VStack, Stack } from "native-base";
import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import TituloComponent from "../global/TituloComponent";

const ProyectosHome = () => {
  const navigate = useNavigate();
  const handleClickMenu = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };
  const FotoPortada = ({ url, texto, nav }) => {
    const imagenDeFondo = {
      backgroundImage: `url("${url}")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "96",
    };

    return (
      <Pressable
        onPress={() => handleClickMenu(nav)}
        w="100%"
        shadow={4}
        borderRadius={10}
        style={imagenDeFondo}
        _hover={{
          shadow: 9,
        }}
      >
        <ZStack w="100%" h={64} alignItems="center" justifyContent="center">
          <Box w="100%" h={64} bg="#000" opacity={0.3} borderRadius={10} />
          <Text
            fontWeight={"bold"}
            color="#fff"
            textAlign={"center"}
            fontFamily={"Graphik"}
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            mx={4}
            style={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
            }}
          >
            {texto}
          </Text>
        </ZStack>
      </Pressable>
    );
  };

  return (
    <VStack space={4} w="100%">
      <TituloComponent
        uno="Forma parte del futuro"
        dos="de la inversión inmobiliaria"
      />
      <Stack
        direction={{ base: "column", md: "row" }}
        w="100%"
        alignItems={"center"}
        space={4}
      >
        <Box w={{ base: "90%", md: "48%" }}>
          <FotoPortada
            url="https://ladressepatrimoine.com/img/BELEHU_ROOF.webp"
            texto="Tu proyecto con nosotros"
            nav="/proyect"
          />
        </Box>
        <Box w={{ base: "90%", md: "25%" }}>
          <FotoPortada
            url="https://blog.simca.mx/hs-fs/hubfs/simca-desarrollos-exito-inversion-inmobiliaria.jpg"
            texto="Modelo de inversión"
            nav="/investment"
          />
        </Box>
        <Box w={{ base: "90%", md: "25%" }}>
          <FotoPortada
            url="https://www.calemanbienesraices.com/wp-content/uploads/2022/08/shark-tower.webp"
            texto="Conversor de divisas"
            nav="/converter"
          />
        </Box>
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        w="100%"
        alignItems={"center"}
        space={4}
      >
        <Box w={{ base: "90%", md: "25%" }}>
          <FotoPortada
            url="https://ladressepatrimoine.com/img/hand.jpg"
            texto="Sobre nosotros"
            nav="/about"
          />
        </Box>
        <Box w={{ base: "90%", md: "25%" }}>
          <FotoPortada
            url="https://ladressepatrimoine.com/img/contacto.jpg"
            texto="Contacto"
            nav="/contact"
          />
        </Box>
        <Box w={{ base: "90%", md: "48%" }}>
          <FotoPortada
            url="https://ladressepatrimoine.com/img/oceantulum.jpg"
            texto="Nuestros desarrollos"
            nav="/properties"
          />
        </Box>
      </Stack>
    </VStack>
  );
};
export default ProyectosHome;
