import { Center, HStack, Stack, Text, VStack, View } from "native-base";
import { BiArea } from "react-icons/bi";
import { IoBedOutline } from "react-icons/io5";
import { LuBath } from "react-icons/lu";

const DetalleEscritorio = ({
  titulo,
  descripcion,
  bath,
  rooms,
  precio,
  area,
  address,
}) => {
  const DatosComponent = ({ icono, cantidad, medida }) => {
    return (
      <Stack
        p={4}
        direction={{ base: "row", md: "column" }}
        space={1}
        alignSelf={{ base: "flex-start", md: "center" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Center>{icono}</Center>
        <Text fontSize={{ base: "md", md: "2xl" }} bold>
          {cantidad}
        </Text>
        <Text fontSize={"md"}>{medida}</Text>
      </Stack>
    );
  };

  return (
    <View>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
      >
        <VStack>
          <Text style={{ fontFamily: "Circular" }} fontSize="2xl">
            {titulo}
          </Text>
          <Text style={{ fontFamily: "Circular" }} fontSize="2xl">
            $ {precio} EUR
          </Text>
          <Text fontSize={"lg"}>{address}</Text>
        </VStack>

        <Stack
          direction={{ base: "column", md: "row" }}
          space={{ base: 0, md: 6 }}
          p={4}
          alignSelf={{ base: "flex-start", md: "center" }}
        >
          <DatosComponent
            icono={<IoBedOutline size="32px" />}
            cantidad={rooms}
            medida="habitaciones"
          />
          <DatosComponent
            icono={<LuBath size="32px" />}
            cantidad={bath}
            medida="baños"
          />
          <DatosComponent
            icono={<BiArea size="32px" />}
            cantidad={area}
            medida="metros cuadrados"
          />
        </Stack>
      </Stack>

      <Text fontSize={"md"}>{descripcion}</Text>
    </View>
  );
};
export default DetalleEscritorio;
