import React, { useState } from "react";
import { View, AlertDialog, Button } from "native-base";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";

const AprobarInmueblesAdmin = () => {
    const { tipo, userId } = useUser();
    const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
    const [selectedInmuebleId, setSelectedInmuebleId] = useState(null);


    // Datos de prueba para la tabla
    const inmuebles = [
        {
            ID: 1,
            Desarrolladora: "Desarrolladora A",
            Titulo: "Casa Grande",
            Direccion: "Avenida Principal, Ciudad",
            FechaCreacion: "2022-03-10",
        },
        {
            ID: 2,
            Desarrolladora: "Desarrolladora B",
            Titulo: "Departamento Moderno",
            Direccion: "Calle Secundaria, Pueblo",
            FechaCreacion: "2022-03-11",
        },
        // Puedes agregar más objetos de inmuebles según sea necesario
    ];

    const handleVerMasClick = (id) => {
        // Lógica para ver más detalles del inmueble
        console.log(`Ver más del inmueble con ID: ${id}`);
    };

    const handleAprobarClick = (id) => {
        // Lógica para aprobar el inmueble
        setSelectedInmuebleId(id);
        setAlertDialogOpen(true);
    };

    const headerText = {
        color: 'white',
        fontWeight: 'bold',
        borderWidth: 1,
        borderColor: '#eeeeee',
        borderStyle: 'solid',
    };

    const handleAprobarInmueble = () => {
        console.log("inmueble aprobado", selectedInmuebleId)
        setAlertDialogOpen(false);
    }

    if (tipo !== "0") {
        return <Login />;
    }

    return (
        <View mx={16} flex={1} mt={16}>
            <h1>Aprobar Inmuebles Admin</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#11003a' }}>
                            <TableCell style={headerText}>ID</TableCell>
                            <TableCell style={headerText}>Desarrolladora</TableCell>
                            <TableCell style={headerText}>Titulo</TableCell>
                            <TableCell style={headerText}>Direccion</TableCell>
                            <TableCell style={headerText}>Fecha de Creación</TableCell>
                            <TableCell style={headerText}>Ver Más</TableCell>
                            <TableCell style={headerText}>Aprobar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inmuebles.map((row) => (
                            <TableRow key={row.ID}>
                                <TableCell>{row.ID}</TableCell>
                                <TableCell>{row.Desarrolladora}</TableCell>
                                <TableCell>{row.Titulo}</TableCell>
                                <TableCell>{row.Direccion}</TableCell>
                                <TableCell>{row.FechaCreacion}</TableCell>
                                <TableCell>
                                    <Button onPress={() => handleVerMasClick(row.ID)}>
                                        Ver Más
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onPress={() => handleAprobarClick(row.ID)}>
                                        Aprobar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <AlertDialog
                isOpen={isAlertDialogOpen}
                leastDestructiveRef={null}
                onClose={() => setAlertDialogOpen(false)}
            >
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>Aprobar Inmueble</AlertDialog.Header>
                    <AlertDialog.Body>
                        {/* Puedes personalizar el contenido según tus necesidades */}
                        Esta acción aprobará el inmueble con ID: {selectedInmuebleId}.
                        ¿Estás seguro de que deseas continuar?
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button onPress={() => setAlertDialogOpen(false)}>Cancelar</Button>
                            <Button colorScheme="success" onPress={() => handleAprobarInmueble(selectedInmuebleId)}>
                                Aprobar
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>

        </View>
    );
};

export default AprobarInmueblesAdmin;
