import { HStack, Text } from "native-base";
import React from "react";

const TituloComponent = ({ uno, dos }) => {
  return (
    <HStack alignSelf="center" space={1} my={5}>
      <Text
        bold
        fontSize="4xl"
        color="muted.700"
        fontFamily={"Graphik"}
        textAlign={"center"}
      >
        {uno} <Text fontWeight={"normal"}>{dos}</Text>
      </Text>
    </HStack>
  );
};

export default TituloComponent;
