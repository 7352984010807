import {
  Button,
  Center,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
  View,
} from "native-base";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import fetchPost from "../helper/fetchPost";
import URL from "../helper/baseURL";
import {
  FaFacebook,
  FaWhatsapp,
  FaRegEnvelope,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";

const UserInformation = () => {
  const { id, nombre } = useParams();
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [foto, setFoto] = useState(null);

  const getData = async () => {
    setIsLoading(true);
    // Primero validar las credenciales

    const data = new FormData();
    data.append("ID", id);
    data.append("nombre", nombre);
    const url = `${URL.BASE_URL}/verPerfil`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta login", res);
    if (res.exito) {
      setUser(res.datos);
      setFoto(
        "https://ladressepatrimoine.com/back/public/uploads/profiles/" +
          res.datos.foto
      );
      setIsLoading(false);
    } else {
      window.alert("Error al buscar usuario, intente más tarde");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const Redes = ({ icono, link, color, colorfont, texto, variant }) => {
    const handleClick = () => {
      window.open(link, "_blank", "noopener,noreferrer");
    };
    return (
      <Button
        borderRadius={10}
        variant={variant}
        colorScheme={color}
        shadow={7}
        p={3}
        onPress={handleClick}
        flex={1}
      >
        <HStack>
          <Center p={1}>{icono}</Center>
          <Text color={colorfont} bold fontSize={"md"} alignSelf={"center"}>
            {texto}
          </Text>
        </HStack>
      </Button>
    );
  };

  return (
    <View bg={"muted.200"}>
      {isLoading ? (
        <Center my={20}>
          <Loader size={"md"} />
          <Text mt={6} bold fontSize={"lg"}>
            Cargando...
          </Text>
        </Center>
      ) : (
        <VStack
          bg="#fff"
          alignSelf={"center"}
          p={12}
          alignItems={"center"}
          my={10}
          maxW={"80%"}
          borderRadius={10}
          shadow={7}
          flex={1}
          space={4}
        >
          <Image
            source={{
              uri: foto,
            }}
            alt="Alternate Text"
            w={56}
            h={56}
            borderRadius={140}
          />
          <Text bold fontSize={"xl"} textAlign={"center"}>
            {user.nombre} {user.apellidoP}
          </Text>

          <HStack space={3}>
            <Redes
              color={"success"}
              icono={<FaWhatsapp color="#fff" size={24} />}
              link={"https://wa.me/" + user.lada + user.telefono}
              texto={user.lada + " " + user.telefono}
              colorfont={"#fff"}
            />
            <Redes
              color={"primary"}
              variant="outline"
              icono={<FaRegEnvelope color="#000" size={24} />}
              link={"mailto:" + user.correo}
              texto={user.correo}
              colorfont={"#000"}
            />
          </HStack>
          <Text fontSize={"lg"} textAlign={"center"}>
            {user.descripcion}
          </Text>
          <HStack space={4}>
            <Redes
              color={"primary"}
              icono={<FaFacebook color="#fff" size={32} />}
              link={"https://www.facebook.com/" + user.facebook}
              colorfont={"#fff"}
            />
            <Redes
              color={"pink"}
              icono={<FaInstagram color="#fff" size={32} />}
              link={"https://www.instagram.com/" + user.instagram}
              colorfont={"#fff"}
            />
            <Redes
              color={"muted"}
              icono={<FaTiktok color="#fff" size={32} />}
              link={"https://www.tiktok.com/" + user.tiktok}
              colorfont={"#fff"}
            />
          </HStack>
        </VStack>
      )}
    </View>
  );
};
export default UserInformation;
