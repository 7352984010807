import { HStack, Text, View, Image, Box } from "native-base";
import FotoPortada from "../components/global/FotoPortada";

const Blog = () => {
  return (
    <View>
      <FotoPortada
        url="https://mareaproperties.com.mx/imagenfr/foto7.jpg"
        texto="Blog"
      />
      <Box ml={20}>
        <h1 style={{ fontFamily: "Baskerville" }}>Blog</h1>
      </Box>
    </View>
  );
};
export default Blog;
