import { View } from "native-base";
import React from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import CrudComponent from "../../../components/admin/CrudComponent";

const AgregarInmuebleAdmin = () => {
  const { tipo, userId } = useUser();

  if (tipo !== "0" && tipo !== "1") {
    return <Login />;
  }

  return (
    <View mt={16}>
      <h1 style={{ textAlign: "center" }}>Agregar Inmueble </h1>

      <CrudComponent />
    </View>
  );
};
export default AgregarInmuebleAdmin;
