export const turquesa = "#000000"; // Azul principal
export const azulclaro = "#021e32"; // Otro tono de azul

// Otros colores
export const amarillo = "#b58230";
export const amarilloclaro = "#e5cf8c";
// Amarillo
export const verde = "#657e50";

export const primario = "#000";
export const secundario = "#000";
export const terciario = "#fff";
export const fondo = "#fff";
