import * as React from "react";
import {
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Center,
  Image,
  ZStack,
  HStack,
  Text,
  Pressable,
} from "native-base";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import fetchPost from "../helper/fetchPost";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useUser } from "../helper/UserContext";
import URL from "../helper/baseURL";
import Loader from "../components/Loader";
import { animateScroll as scroll } from "react-scroll";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Login = () => {
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");

  // const [correo, setCorreo] = useState("ADMIN@GMAIL.COM");
  // const [password, setPassword] = useState("qwerty123/*");

  const { login, userId } = useUser();
  const handleLogin = (
    id_user,
    tipoUser,
    nombreUser,
    apellidoP,
    apellidoM,
    correoUser,
    telUser
  ) => {
    // Lógica para el inicio de sesión
    login(
      id_user,
      tipoUser,
      nombreUser,
      apellidoP,
      apellidoM,
      correoUser,
      telUser
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  //show/hide pass
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  //para navegar a otras vistas
  const navigate = useNavigate();
  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem("userID", value);
    } catch (e) {
      // saving error
    }
  };

  const storeTipoUser = async (value) => {
    try {
      await AsyncStorage.setItem("tipoUser", value);
    } catch (e) {
      // saving error
    }
  };
  const Login = async () => {
    setIsLoading(true);
    // Primero validar las credenciales
    if (!validarCredenciales()) {
      setIsLoading(false); // Si la validación falla, detener la ejecución
      return;
    }
    const dataLogin = new FormData();
    dataLogin.append("correo", correo.trim());
    dataLogin.append("password", password);
    const url = `${URL.BASE_URL}/login`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta login", res);

    if (res.resultado === false) {
      window.alert(res.mensaje);
      setIsLoading(false);
      return;
    } else {
      handleLogin(
        res.user.id_usuario,
        res.user.tipoUsuario,
        res.user.nombre,
        res.user.apellidoP,
        res.user.apellidoM,
        res.user.correo,
        res.user.telefono
      );

      storeData(res.user.id_usuario);
      storeTipoUser(res.user.tipoUsuario);
      window.alert(res.mensaje);

      setIsLoading(false);

      if (res.user.TipoUsuario === "0" || "1" || "2") {
        navigate("/UserPanel");
      } else {
        window.alert(
          "No tienes permisos necesarios para acceder a esta sección"
        );
      }
    }
  };

  // Función para validar el correo electrónico y la contraseña
  const validarCredenciales = () => {
    // Verifica si el correo contiene '@'
    const esCorreoValido = correo.includes("@");
    // Verifica si la contraseña tiene más de 5 caracteres
    const esPasswordValida = password.length > 5;

    if (!esCorreoValido) {
      window.alert("Por favor ingresa un correo electrónico válido.");
      return false;
    }

    if (!esPasswordValida) {
      window.alert("La contraseña debe tener más de 5 caracteres.");
      return false;
    }

    return true;
  };
  const estiloLink = {
    color: "#005db4",
    fontWeight: "bold",
    fontSize: "16px", // Tamaño de fuente similar a "sm"

    textAlign: "center", // Para eliminar el subrayado predeterminado
  };

  if (userId) {
    navigate("/UserPanel");
  }

  useEffect(() => {
    if (userId) {
      navigate("/UserPanel");
    }
  }, []);

  const handleRegistro = () => {
    scroll.scrollToTop();
    navigate("/signup");
  };

  return (
    <ZStack h="45rem" w="100%">
      <Image
        source={{
          uri: "https://ladressepatrimoine.com/img/login.png",
        }}
        alt="Alternate Text"
        w="100%"
        h={96}
      />

      <Center
        alignSelf="center"
        bg="#fff"
        flex={1}
        mt={16}
        shadow={7}
        borderRadius={10}
      >
        <Image
          alignSelf="center"
          source={{
            uri: `https://ladressepatrimoine.com/img/logo-pngblanco.png`,
          }}
          alt="Alternate Text"
          w={56}
          h={40}
          mt={5}
          resizeMode="cover"
        />

        <Box safeArea p="2" py="8" w="90%" maxW="290">
          <Heading
            mt="1"
            _dark={{
              color: "warmGray.200",
            }}
            color="coolGray.600"
            fontWeight="medium"
            size="md"
            alignSelf={"center"}
            textAlign={"center"}
          >
            Inicia sesión para continuar
          </Heading>

          <VStack space={3} mt="5" flex={1}>
            <FormControl>
              <FormControl.Label>Email</FormControl.Label>
              <Input
                placeholder="Email"
                onChangeText={(val) => setCorreo(val.toUpperCase())}
                value={correo}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label> Contraseña</FormControl.Label>
              <Input
                type={show ? "text" : "password"}
                placeholder="Contraseña"
                onChangeText={(val) => setPassword(val)}
                value={password}
                InputRightElement={
                  <Button
                    ml={1}
                    variant="link"
                    roundedLeft={0}
                    roundedRight="md"
                    onPress={handleClick}
                  >
                    {show ? <FaRegEyeSlash /> : <FaRegEye />}
                  </Button>
                }
              />
              {/* <Link _text={{
              fontSize: "xs",
              fontWeight: "500",
              color: "indigo.500"
            }} alignSelf="flex-end" mt="1">
            ¿Olvidaste tu contraseña?
              </Link> */}
            </FormControl>

            {isLoading ? (
              <Loader size="lg" color="primary.500" />
            ) : (
              <Button
                mt="2"
                colorScheme="darkBlue"
                onPress={() => Login()}
                shadow={6}
              >
                Iniciar sesión
              </Button>
            )}

            <HStack mt="6" justifyContent="center" alignItems={"center"}>
              <Text
                fontSize="md"
                color="coolGray.600"
                _dark={{
                  color: "warmGray.200",
                }}
              >
                ¿Eres nuevo?{" "}
              </Text>
              <Pressable onPress={() => handleRegistro()}>
                <Link style={estiloLink}>Registrarse</Link>
              </Pressable>
            </HStack>
          </VStack>
        </Box>
      </Center>
    </ZStack>
  );
};

export default Login;
