import React, { createContext, useContext, useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [apellido, setApellido] = useState(null);
  const [apellido2, setApellido2] = useState(null);
  const [correo, setCorreo] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [instagramUser, setInstagramUser] = useState(null);
  const [facebookUser, setFacebookUser] = useState(null);
  const [tiktokUser, setTiktokUser] = useState(null);
  const [carrito, setCarrito] = useState([]);
  const [totalContext, setTotalContext] = useState(0);

  // Obtener el estado del usuario
  useEffect(() => {
    const obtenerUsuario = async () => {
      try {
        const usuarioActual = await AsyncStorage.getItem("userId");
        setUserId(usuarioActual);

        const usuarioTipoActual = await AsyncStorage.getItem("tipo");
        setTipo(usuarioTipoActual);

        // Obtener los datos adicionales
        const nombreActual = await AsyncStorage.getItem("nombre");
        setNombre(nombreActual);

        const apellidoActual = await AsyncStorage.getItem("apellido");
        setApellido(apellidoActual);

        const correoActual = await AsyncStorage.getItem("correo");
        setCorreo(correoActual);
      } catch (error) {
        console.error("Error al obtener datos desde AsyncStorage:", error);
      }
    };
    obtenerUsuario();
  }, []);

  const login = async (
    id,
    tipoUser,
    nombreUser,
    apellidoP,
    apellidoM,
    correoUser,
    telUser
  ) => {
    setUserId(id);
    setTipo(tipoUser);
    setNombre(nombreUser);
    setApellido(apellidoP);
    setApellido2(apellidoM);
    setCorreo(correoUser);
    setTelefono(telUser);

    try {
      await AsyncStorage.setItem("userId", id);
      await AsyncStorage.setItem("tipo", tipoUser);
      await AsyncStorage.setItem("nombre", nombreUser);
      await AsyncStorage.setItem("apellido", apellidoP);
      await AsyncStorage.setItem("apellido2", apellidoM);
      await AsyncStorage.setItem("correo", correoUser);
      await AsyncStorage.setItem("telefono", telUser);
    } catch (error) {
      console.error("Error saving data to AsyncStorage in login(): ", error);
    }
  };

  const actualizaUser = async (nombreUser, apellidoP, apellidoM, telUser) => {
    setNombre(nombreUser);
    setApellido(apellidoP);
    setApellido2(apellidoM);
    setTelefono(telUser);

    try {
      await AsyncStorage.setItem("nombre", nombreUser);
      await AsyncStorage.setItem("apellido", apellidoP);
      await AsyncStorage.setItem("apellido2", apellidoM);
      await AsyncStorage.setItem("telefono", telUser);
    } catch (error) {
      console.error("Error saving data to AsyncStorage in login(): ", error);
    }
  };

  const logout = async () => {
    setUserId(null);
    setTipo(null);
    try {
      await AsyncStorage.removeItem("userId");
      await AsyncStorage.removeItem("tipo");
    } catch (error) {
      console.error("Error saving data to AsyncStorage f. login( ): ", error);
    }
  };

  // Llama a la función para obtener el GranTotal

  const totalStripe = (usd) => {
    setTotalContext(usd);
    console.log("Total usd:", totalContext);
  };

  return (
    <UserContext.Provider
      value={{
        actualizaUser,
        userId,
        nombre,
        apellido,
        apellido2,
        telefono,
        correo,
        tipo,
        carrito,
        login,
        totalContext,
        totalStripe,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
