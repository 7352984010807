import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./fonts/fonts.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import Contacto from "./pages/Contacto";
import Footer from "./components/Footer";
import Privacidad from "./pages/Privacidad";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Registro from "./pages/Registro";
import TerminosCondiciones from "./pages/TerminosCondiciones";
import Inmuebles from "./pages/Inmuebles";
import ModeloInversion from "./pages/ModeloInversion";
import Simulador from "./pages/Simulador";
import TuProyecto from "./pages/TuProyecto";
import Nosotros from "./pages/Nosotros";
import SimuladorHipoteca from "./pages/SimuladorHipoteca";
import SimuladorRentabilidad from "./pages/SimuladorRentabilidad";

import UserPermiso from "./pages/private/UserPermiso";
import UserInfo from "./pages/private/UserInfo";
import VerUsuariosAdmin from "./pages/private/admin/VerUsuariosAdmin";
import ApartadosAdmin from "./pages/private/admin/ApartadosAdmin";
import AgregarInmuebleAdmin from "./pages/private/admin/AgregarInmuebleAdmin";
import VerInmueblesAdmin from "./pages/private/admin/VerInmueblesAdmin";
import AprobarInmueblesAdmin from "./pages/private/admin/AprobarInmueblesAdmin";
import AgregarUsuarioAdmin from "./pages/private/admin/AgregarUsuarioAdmin";
import SolicitudesDesarrollo from "./pages/private/desarrolladora/SolicitudesDesarrollo";
import SuscripcionStatusDes from "./pages/private/desarrolladora/SuscripcionStatusDes";
import Favoritos from "./pages/Favoritos";
import SolicitudesBroker from "./pages/private/broker/SolicitudesBroker";
import SuscripcionStatusBroker from "./pages/private/broker/SuscripcionStatusBroker";
import SolicitudesUser from "./pages/private/user/SolicitudesUser";
import VerInmueblesDes from "./pages/private/desarrolladora/VerInmueblesDes";
import Blog from "./pages/Blog";
import ConversorDivisas from "./pages/ConversorDivisas";
import DetalleInmueble from "./pages/DetalleInmueble";
import EditarCrudComponent from "./components/admin/EditarCrudComponent";
import VerBrokersDes from "./pages/private/desarrolladora/VerBrokersDes";
import UserInformation from "./pages/UserInformation";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="/contact" element={<Contacto />} />
          <Route path="/properties" element={<Inmuebles />} />
          <Route path="property/:id/:titulo/" element={<DetalleInmueble />} />
          <Route
            path="userinformation/:id/:nombre/"
            element={<UserInformation />}
          />
          <Route path="/investment" element={<ModeloInversion />} />
          <Route path="/simulator" element={<Simulador />} />
          <Route path="/proyect" element={<TuProyecto />} />
          <Route path="/about" element={<Nosotros />} />
          <Route path="/VerBrokersDesarrolladora" element={<VerBrokersDes />} />
          <Route path="/mortgage_simulator" element={<SimuladorHipoteca />} />
          <Route
            path="/profitability_simulator"
            element={<SimuladorRentabilidad />}
          />

          <Route path="/privacy" element={<Privacidad />} />
          <Route path="/terms&conditions" element={<TerminosCondiciones />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Registro />} />

          {/* //admin y user panel */}
          <Route path="/userPanel" element={<UserPermiso />} />

          {/* Admin */}
          <Route path="/VerUsuariosAdmin" element={<VerUsuariosAdmin />} />
          <Route path="/ApartadosAdmin" element={<ApartadosAdmin />} />
          <Route
            path="/AgregarInmuebleAdmin"
            element={<AgregarInmuebleAdmin />}
          />
          <Route
            path="/EditarInmueble/:id_inmueble/:id_user"
            element={<EditarCrudComponent />}
          />
          <Route path="/VerInmueblesAdmin" element={<VerInmueblesAdmin />} />
          <Route path="/VerInmueblesDes" element={<VerInmueblesDes />} />
          <Route
            path="/AprobarInmueblesAdmin"
            element={<AprobarInmueblesAdmin />}
          />
          <Route
            path="/AgregarUsuarioAdmin"
            element={<AgregarUsuarioAdmin />}
          />

          {/* Desarrolladora */}
          <Route path="/UserInfo" element={<UserInfo />} />
          <Route
            path="/SolicitudesDesarrollo"
            element={<SolicitudesDesarrollo />}
          />

          <Route
            path="/SuscripcionStatusDes"
            element={<SuscripcionStatusDes />}
          />
          <Route path="/VerInmueblesDes" element={<VerInmueblesDes />} />

          {/* Broker */}
          <Route path="/SolicitudesBroker" element={<SolicitudesBroker />} />
          <Route
            path="/SuscripcionStatusBroker"
            element={<SuscripcionStatusBroker />}
          />
          <Route path="/Favoritos" element={<Favoritos />} />

          {/* Cliente */}
          <Route path="/userPanel" element={<UserPermiso />} />
          <Route path="/SolicitudesUser" element={<SolicitudesUser />} />

          <Route path="/blog" element={<Blog />} />
          <Route path="/converter" element={<ConversorDivisas />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
