import React from "react";
import { useUser } from "../../helper/UserContext";
import Login from "../Login";
import UserPanel from "./user/UserPanel";
import AdminPanel from "./admin/AdminPanel";
import DesarrolladoraPanel from "./desarrolladora/DesarrolladoraPanel";
import BrokerPanel from "./broker/BrokerPanel";

const UserPermiso = () => {
  const { tipo, userId } = useUser();
  if (userId === null) {
    return <Login />;
  }

  // Determinar qué componente mostrar basado en el tipo
  switch (tipo) {
    case "0":
      return <AdminPanel />;
    case "1":
      return <DesarrolladoraPanel />;
    case "2":
      return <BrokerPanel />;
    case "3":
      return <UserPanel />;
    default:
      return <Login />;
  }
};

export default UserPermiso;
