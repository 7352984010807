import React from "react";
import { Stack, Box } from "native-base";
import InmuebleFav from "./home/InmuebleFav";
import ContactoSimple from "./ContactoSimple";

const ContactoFooter = () => {
  return (
    <Stack
      my={5}
      direction={{
        base: "column",
        lg: "row",
      }}
      mt={10}
      w="90%"
      alignSelf="center"
    >
      <Box
        w={{
          base: "100%",
          lg: "50%",
        }}
        pb={20}
      >
        <InmuebleFav />
      </Box>

      <Box
        w={{
          base: "100%",
          lg: "50%",
        }}
      >
        <ContactoSimple />
      </Box>
    </Stack>
  );
};
export default ContactoFooter;
