import { Box, View, Text, Button, VStack, Icon, Divider, HStack } from "native-base";
import React, { useState } from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import { MdPayment, MdCheckCircleOutline } from "react-icons/md";



const SuscripcionStatusDes = () => {

    const { tipo, userId } = useUser();

    const registrationDate = new Date(2024, 0, 13); // Reemplaza esto con la fecha real de registro del usuario
    const membershipExpirationDate = new Date(2023, 4, 23); // Reemplaza esto con la fecha real de vencimiento de la membresía

    const [isMembershipPaid, setIsMembershipPaid] = useState(true);

    const handleRenewMembership = () => {
        // Agrega la lógica para renovar la membresía aquí
        console.log('Renovar membresía');
    };

    if (tipo !== "1" && tipo !== "2") {
        return <Login />;
    }

    return (
        <View my={16}>
            <h1 style={{ textAlign: "center" }}>
                Mi membresía
            </h1>

            <VStack p={8} shadow={6} borderWidth={1} space={4} borderColor="muted.400s"
                borderRadius={10} alignSelf="center" bg="muted.100">
                <Text fontSize="lg" fontWeight="bold" >
                    Estado de la Membresía
                </Text>
                <Divider />
                <Text >
                    <b>Fecha de último pago:</b>  {registrationDate.toLocaleDateString()} <br />
                    <Text color="muted.500">(Día/Mes/Año)</Text>
                </Text>
                <Text>
                    <b>Fecha de Vencimiento:</b>  {membershipExpirationDate.toLocaleDateString()}
                </Text>
                <Text>
                    <b> Estado de Pago: </b>{isMembershipPaid ? 'Pagado' : 'Pendiente'}
                </Text>
                {isMembershipPaid ? (
                    <HStack space={2} p={3} bg={"green.300"} flex={1} alignItems="center"
                        textAlign="center" justifyContent="center" borderRadius={10} shadow={4}>
                        <MdCheckCircleOutline size={24} />
                        <Text bold textAlign="center">
                            Membresía al día
                        </Text>
                    </HStack>

                ) : (
                    <Button onPress={handleRenewMembership}
                        mt={4} shadow={4} flex={1}
                        leftIcon={<Icon as={<MdPayment color="#fff" size={24} />} />}>
                        Renovar Membresía
                    </Button>
                )}
            </VStack>
        </View>
    );

};
export default SuscripcionStatusDes;