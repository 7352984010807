const URL = {
    // BASE_URL : "https://v-csoft.com/leappi/"
    BASE_URL: "https://ladressepatrimoine.com/back/public/",

}





export default URL;