import { Heading, View, Box } from "native-base";
import React from "react";
import ContactoFooter from "../components/ContactoFooter";
import CalculadoraHipoteca from "../components/simulador/CalculadoraHipoteca";
import FotoPortada from "../components/global/FotoPortada";

const SimuladorHipoteca = () => {
  return (
    <View w="100%">
      <FotoPortada
        url="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab"
        texto="Simulador de Hipoteca"
      />

      <Box alignSelf="center" my={10}>
        <CalculadoraHipoteca />
      </Box>

      <ContactoFooter />
    </View>
  );
};
export default SimuladorHipoteca;
