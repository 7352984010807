import React, { useState } from 'react';

function CalculadoraHipoteca() {
    const [montoPrestamo, setMontoPrestamo] = useState('');
    const [tasaInteres, setTasaInteres] = useState('');
    const [plazoAnos, setPlazoAnos] = useState('');
    const [pagoInicial, setPagoInicial] = useState('');
    const [pagoMensual, setPagoMensual] = useState('');
    const [monto2, setMonto2] = useState('');

    const calcularPagoMensual = () => {
        const tasaInteresMensual = Number(tasaInteres) / 12 / 100;
        const numeroPagos = Number(plazoAnos) * 12;
        const montoPrestamoAjustado = Number(montoPrestamo) - Number(pagoInicial);

        const pagoMensualCalculado = montoPrestamoAjustado *
            (tasaInteresMensual / (1 - Math.pow(1 + tasaInteresMensual, -numeroPagos)));

        if (!isNaN(pagoMensualCalculado)) {
            setPagoMensual(pagoMensualCalculado.toFixed(2));
            setMonto2(montoPrestamoAjustado);
        } else {
            setPagoMensual('Error en el cálculo');
        }
    };

    return (
        <div>
            <h2>Calculadora de Hipoteca</h2>
            <div>
                <label>Valor de la propiedad: </label>
                <input
                    type="number"
                    value={montoPrestamo}
                    onChange={e => setMontoPrestamo(e.target.value)}
                />
            </div>
            <div>
                <label>Pago inicial: </label>
                <input
                    type="number"
                    value={pagoInicial}
                    onChange={e => setPagoInicial(e.target.value)}
                />
            </div>
            <div>
                <label>Monto del préstamo: </label>
                <input
                    type="number"
                    disabled="true"
                    value={monto2}
                    onChange={e => setMontoPrestamo(e.target.value)}
                />
            </div>
            <div>
                <label>Tasa de interés (% anual): </label>
                <input
                    type="number"
                    step="0.01"
                    value={tasaInteres}
                    onChange={e => setTasaInteres(e.target.value)}
                />
            </div>
            <div>
                <label>Plazo (años): </label>
                <input
                    type="number"
                    value={plazoAnos}
                    onChange={e => setPlazoAnos(e.target.value)}
                />
            </div>

            <div>
                <button onClick={calcularPagoMensual}>Calcular</button>
            </div>
            {pagoMensual && (
                <div>
                    <h3>Pago mensual: ${pagoMensual}</h3>
                </div>
            )}
        </div>
    );
}

export default CalculadoraHipoteca;
