import { Text, View, Stack, VStack, Pressable, Box } from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList } from "react-icons/pi";
import { MdOutlineWorkHistory } from "react-icons/md";
import OpcionUser from "../../../components/global/OpcionUser";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";

import { BsShop } from "react-icons/bs";
import { LuHotel } from "react-icons/lu";
import { TbHomeEdit } from "react-icons/tb";

const UserPanel = () => {
    const { logout, nombre, apellido, correo } = useUser();

    const navigate = useNavigate();

    const handleSalir = () => {
        // Lógica para el inicio de sesión
        logout();
        navigate("/");
    };




    const UserNav = (nav) => {
        navigate(nav);
    }



    const stackContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: "center",
        alingItems: "center"
    };

    return (
        <View mt={24}>
            <Text bold fontSize={"2xl"} textAlign="center">
                BIENVENIDO
            </Text>

            <Text bold fontSize={"2xl"} textAlign="justify" px={10}>
                Cuenta
            </Text>

            <Text bold fontSize={"lg"} px={10}>
                {nombre} {apellido}.
            </Text>
            <Text fontWeight={"semibold"} fontSize={"md"} px={10}>
                {correo}
            </Text>


            <Box w="80%" alignSelf="center">
                <div style={stackContainerStyle}>
                    <OpcionUser
                        titulo="Información personal"
                        texto="Visualiza y edita tus datos personales"
                        funcion={() => UserNav("/UserInfo")}
                        icon={PiUserList} />


                    <OpcionUser
                        titulo="Solicitudes de apartado"
                        texto="Ver las solicitudes de brokers o clientes"
                        funcion={() => UserNav("/SolicitudesUser")}
                        icon={LuHotel} />

                    <OpcionUser
                        titulo="Favoritos"
                        texto="Revisa tus favoritos"
                        funcion={() => UserNav("/Favoritos")}
                        icon={MdOutlineWorkHistory} />

                </div>
            </Box>


            <LogoutConfirmationPopover onConfirm={handleSalir} />

        </View>
    );

}
export default UserPanel;