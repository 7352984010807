import { Box, Button, Center, Popover, View } from "native-base";
import React, { useState, useEffect } from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import fetchPost from "../../../helper/fetchPost";
import URL from "../../../helper/baseURL";
import { Spinner, HStack, Pressable } from "native-base";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { FaToggleOn, FaToggleOff, FaRegTrashAlt, FaEdit } from "react-icons/fa";
import AsyncStorage from "@react-native-async-storage/async-storage";

const VerInmueblesDes = () => {
  const { tipo, userId } = useUser();
  const [inmuebles, setInmuebles] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [idUser, setIdUser] = useState(userId);

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        getInmuebles(value);
        setIdUser(value);
      }
    } catch (e) {
      // error reading value
    }
  };

  useEffect(() => {
    if (userId === null) {
      getData();
    } else {
      getInmuebles(userId);
    }
  }, []);

  const navigate = useNavigate();

  const handleNav = (id, nombre) => {
    navigate(`/property/${id}/${nombre}`);
  };

  const handleEdit = (id_inmueble, id_user) => {
    navigate(`/EditarInmueble/${id_inmueble}/${id_user}`);
  };

  const getInmuebles = async (id) => {
    console.log("id ujser ", id);
    const dataLogin = new FormData();
    dataLogin.append("id_usuario", id);
    const url = `${URL.BASE_URL}desarrolladora/verInmueblesDes`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.success) {
      setInmuebles(res.data);
      setIsLoading(false);
    }
  };

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  if (tipo !== "1") {
    return <Login />;
  }

  const handleBorrar = async (idInmueble) => {
    setIsLoadingDelete(true);
    const dataLogin = new FormData();
    dataLogin.append("id", idInmueble);
    const url = `${URL.BASE_URL}desarrolladora/eliminarInmueble`;
    const options = {
      method: "POST",
      body: dataLogin,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.resultado) {
      setIsLoadingDelete(false);
      window.alert(res.mensaje);
      getInmuebles(idUser);
    } else {
      window.alert(
        "Error al eliminar, comprueba tu conexión e intentalo más tarde, si el problema persiste contacte al administrador"
      );
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner size="xl" py={20} alignSelf={"center"} />
      ) : (
        <View mx={10} flex={1} my={16}>
          <h1 style={{ textAlign: "center" }}>Inmuebles registrados</h1>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#11003a" }}>
                  <TableCell style={headerText}>ID</TableCell>
                  <TableCell style={headerText}>Desarrolladora</TableCell>
                  <TableCell style={headerText}>Titulo</TableCell>
                  <TableCell style={headerText}>Direccion</TableCell>
                  <TableCell style={headerText}>Precio</TableCell>
                  <TableCell style={headerText}>Unidades</TableCell>
                  <TableCell style={headerText}>Habilitado</TableCell>
                  <TableCell style={headerText}>Link</TableCell>

                  <TableCell style={headerText}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inmuebles.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.nombre_usuario}</TableCell>
                    <TableCell>{row.nombre}</TableCell>
                    <TableCell>{row.ubicacion}</TableCell>
                    <TableCell>{row.precio}</TableCell>
                    <TableCell>{row.unidades}</TableCell>
                    <TableCell>
                      {/* {row.status} */}
                      {row.status === "1" ? (
                        <Center>
                          <FaToggleOn
                            alignSelf={"center"}
                            size={"40px"}
                            color={"#2e7d32"}
                          />
                        </Center>
                      ) : (
                        <Center>
                          <FaToggleOff
                            alignSelf={"center"}
                            size={"40x"}
                            color={"#a3a3a3"}
                          />
                        </Center>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        px={2}
                        onPress={() => handleNav(row.id, row.nombre)}
                      >
                        Ver
                      </Button>
                    </TableCell>
                    <TableCell>
                      <HStack space={4}>
                        <Pressable
                          bg="#16a34a"
                          p={2}
                          borderRadius={10}
                          shadow={3}
                          onPress={() => handleEdit(row.id, idUser)}
                        >
                          <FaEdit color="#fff" size={"18px"} />
                        </Pressable>
                        <Box w="100%" alignItems="center">
                          <Popover
                            trigger={(triggerProps) => {
                              return (
                                <Pressable
                                  bg="#e11d48"
                                  p={2}
                                  borderRadius={10}
                                  shadow={3}
                                  {...triggerProps}
                                >
                                  <FaRegTrashAlt color="#fff" size={"18px"} />
                                </Pressable>
                              );
                            }}
                          >
                            <Popover.Content
                              accessibilityLabel="Borrar Inmueble"
                              w="56"
                            >
                              <Popover.Arrow />
                              <Popover.CloseButton />
                              <Popover.Header>
                                Borrar inmueble ID {row.id}
                              </Popover.Header>
                              <Popover.Body>
                                Se eliminara el inmueble {row.nombre}
                                ¿Estás seguro? Esta acción no se puede deshacer.
                              </Popover.Body>
                              <Popover.Footer justifyContent="flex-end">
                                <Button.Group space={2}>
                                  <Button
                                    colorScheme="coolGray"
                                    variant="ghost"
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    colorScheme="danger"
                                    onPress={() => handleBorrar(row.id)}
                                  >
                                    Eliminar
                                  </Button>
                                </Button.Group>
                              </Popover.Footer>
                            </Popover.Content>
                          </Popover>
                        </Box>
                      </HStack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      )}
    </>
  );
};
export default VerInmueblesDes;
