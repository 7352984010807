import React, { useState, useEffect } from "react";
import {
  VStack,
  Select,
  Input,
  FormControl,
  TextArea,
  Box,
  Button,
  View,
  useToast,
  Text,
  Spinner,
  HStack,
  Stack,
  CheckIcon,
} from "native-base";
import URL from "../helper/baseURL";
import fetchPost from "../helper/fetchPost";
import { turquesa, azulclaro } from "../helper/colors";
import ReactInputMask from "react-input-mask";
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } from "react-simple-captcha";

const ContactoSimple = () => {
  const toast = useToast();

  const mostrarToast = (mensaje) => {
    toast.show({
      description: mensaje,
      placement: "top", // Puedes cambiar la ubicación según sea necesario
      render: () => {
        return (
          <Box
            bg={azulclaro}
            px="6"
            py="4"
            rounded="sm"
            mb={5}
            borderWidth={3}
            borderColor={turquesa}
            shadow={7}
          >
            <Text fontFamily="Circular" color="white">
              {mensaje}
            </Text>
          </Box>
        );
      },
    });
  };

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [lada, setLada] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const validarFormulario = () => {
    // let userCaptchaValue = document.getElementById("user_captcha_input").value;
    // Validar nombre
    if (nombre.length <= 3) {
      mostrarToast("El nombre debe tener más de 3 letras.");
      return false;
    }

    // Validar correo electrónico
    const regexEmail = /\S+@\S+\.\S+/;
    if (!regexEmail.test(email)) {
      mostrarToast("Por favor, introduce un correo electrónico válido.");
      return false;
    }

    // Validar teléfono
    if (!telefono.match(/^\d{10,13}$/)) {
      mostrarToast("El teléfono debe tener al menos 10 dígitos");
      return false;
    }

    if (lada === "") {
      mostrarToast("Ingrese LADA");
      return false;
    }

    // Validar mensaje
    if (mensaje.trim() === "") {
      mostrarToast("Por favor, escribe un mensaje.");
      return false;
    }

    // if (!validateCaptcha(userCaptchaValue)) {
    //   mostrarToast("Captcha Incorrecto");
    //   return false;
    // }

    return true;
  };

  //falta configurar
  const handleSubmit = async () => {
    validarFormulario();
    if (!validarFormulario()) {
      return; // Detiene la función si la validación falla
    } else {
      const BASE_URL = URL.BASE_URL;

      const dataContacto = new FormData();
      //para enviar datos por POST
      dataContacto.append("nombre", nombre);
      dataContacto.append("email", email);
      dataContacto.append("telefono", lada + " " + telefono);
      dataContacto.append("mensaje", mensaje);
      const url = `${BASE_URL}enviarcorreo`;
      const options = {
        method: "POST",
        body: dataContacto,
      };
      setIsLoading(true);
      console.log("url: ", url);
      try {
        const response = await fetchPost(url, options);
        console.log("respuesta: ", response);
        if (response.status) {
          // Si el estado es verdadero, muestra un mensaje de éxito
          window.alert("Mensaje enviado");
          setIsLoading(false);
        } else {
          // Si el estado es falso, muestra un mensaje de error
          window.alert("Error al enviar el mensaje");
          setIsLoading(false);
        }
      } catch (error) {
        // Manejar cualquier otro error (por ejemplo, problemas de red)
        window.alert("Error al procesar la solicitud");
        setIsLoading(false);
      }
    }
  };
  const handleChangePhone = (e) => {
    setTelefono(e.target.value);
  };

  return (
    <View flex={1}>
      {/* FORMULARIO CONTACTO */}
      <VStack
        flex={1}
        alignSelf="center"
        bg="#fff"
        shadow={7}
        pb={2}
        borderRadius={5}
      >
        <Text
          style={{ fontFamily: "Graphik" }}
          bg={turquesa}
          pt={2}
          fontSize="2xl"
          color={"#fff"}
          pl={10}
        >
          Contáctanos
        </Text>
        <Text
          style={{ fontFamily: "Graphik" }}
          bg={turquesa}
          pb={2}
          fontSize="xl"
          color={"#fff"}
          pl={10}
        >
          para beneficiarse de inversiones adecuadas
        </Text>

        <VStack
          w="90%"
          space={4}
          alignSelf="center"
          bg="#fff"
          px={2}
          mt={3}
          borderRadius={5}
          my={2}
        >
          <Text fontFamily="Graphik" py={2} fontSize="md">
            ¿Estás interesado en la inversión en alquiler? Introduzca la
            información a contactar por teléfono de forma gratuita. Es libre y
            sin compromiso.
          </Text>

          <FormControl isRequired>
            <FormControl.Label>Nombre</FormControl.Label>
            <Input
              shadow={7}
              value={nombre}
              placeholder={"Nombre"}
              onChangeText={(e) => setNombre(e)}
            />
          </FormControl>

          <HStack flex={1} space={5}>
            <VStack>
              <FormControl.Label>Email</FormControl.Label>

              <FormControl isRequired flex={1}>
                {/* <FormControl.Label _text={{ bold: true }}>Correo: </FormControl.Label> */}
                <Input
                  shadow={6}
                  w={64}
                  value={email}
                  type="email"
                  placeholder="Correo Electrónico"
                  onChangeText={(e) => setEmail(e)}
                />
              </FormControl>
            </VStack>

            <HStack space={2}>
              <FormControl w={32}>
                <FormControl.Label>LADA</FormControl.Label>
                <Select
                  selectedValue={lada}
                  w={32}
                  accessibilityLabel="LADA"
                  placeholder="LADA"
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size="3" />,
                  }}
                  onValueChange={(itemValue) => setLada(itemValue)}
                >
                  <Select.Item label="MX (+52)" value="+52" />
                  <Select.Item label="US (+1)" value="+1" />
                  <Select.Item label="FR (+33)" value="+33" />
                  <Select.Item label="CA (+1)" value="+1" />
                </Select>
              </FormControl>
              <FormControl flex={1}>
                <FormControl.Label>Teléfono</FormControl.Label>
                <ReactInputMask
                  mask="999 999 9999"
                  value={telefono}
                  onChange={handleChangePhone}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      color={"#000"}
                      value={telefono}
                      onChange={handleChangePhone}
                    />
                  )}
                </ReactInputMask>
              </FormControl>
            </HStack>
          </HStack>
          <Select placeholder="Eres: " minWidth="64">
            <Select.Item label="Particular" value="ts" />
            <Select.Item label="Asesor inmobiliario" value="c" />
            <Select.Item label="Asesor de gestión de patrimonio" value="js" />
            <Select.Item label="Asesor financiero" value="py" />
            <Select.Item
              label="Dueño de una agencia inmobiliaria"
              value="java"
            />
            <Select.Item label="Masterbroker" value="java" />
            <Select.Item label="Desarrollador inmobiliario" value="java" />
          </Select>

          <FormControl isRequired>
            {/* <FormControl.Label _text={{ bold: true }}>Mensaje: </FormControl.Label> */}
            <TextArea
              shadow={6}
              value={mensaje}
              placeholder="Escribe tu mensaje aquí"
              onChangeText={(e) => setMensaje(e)}
            />
          </FormControl>

          {/* <Checkbox.Group accessibilityLabel="terminos y condiciones"
                        onChange={setGroupValues} value={groupValues} >
                        <Checkbox value="one" my={2}>
                            <Text fontSize="xs" fontFamily="Graphik">
                                {t("contacto.check")}
                            </Text>

                        </Checkbox>
                        <Checkbox value="two">
                            <Text fontSize="xs" fontFamily="Graphik">
                                {t("contacto.check2")}
                            </Text>
                        </Checkbox>
                    </Checkbox.Group> */}

          {/* <Stack
            direction={["column", "row"]}
            space={8}
            mt={2}
            alignSelf="center"
          >
            <LoadCanvasTemplate
              reloadText="Actualiza el Captcha"
              reloadColor={turquesa}
            />
            <input type="text" id="user_captcha_input" placeholder=" Captcha" />
          </Stack> */}
          {isLoading ? (
            <Spinner mt={2} size={56} color={turquesa} />
          ) : (
            <Button
              shadow={6}
              colorScheme="info"
              onPress={() => handleSubmit()}
              my={4}
              size="lg"
              py={2}
              w="60%"
              alignSelf="center"
            >
              Enviar
            </Button>
          )}
        </VStack>
      </VStack>
    </View>
  );
};

export default ContactoSimple;
