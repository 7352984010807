import React, { useEffect } from "react";
import { Text, View, Stack, Heading, Box } from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";
import OpcionUser from "../../../components/global/OpcionUser";
import { TbHomeEdit, TbHomeHeart } from "react-icons/tb";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { HiOutlineUserAdd } from "react-icons/hi";
import { LiaUsersSolid } from "react-icons/lia";
import { animateScroll as scroll } from "react-scroll";
import { PiUserList } from "react-icons/pi";
const AdminPanel = () => {
  const { logout, nombre, apellido, correo, userId } = useUser();

  const navigate = useNavigate();

  const handleSalir = () => {
    // Lógica para el inicio de sesión
    logout();
    scroll.scrollToTop();
    navigate("/");
  };

  useEffect(() => {
    console.log("id user admin: ", userId);
  }, []);

  const UserNav = (nav) => {
    navigate(nav);
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };

  return (
    <View my={16}>
      <Heading bold fontSize={"2xl"} textAlign="center" mt={3}>
        BIENVENIDO
      </Heading>
      <Stack my={3} ml="20%">
        <Text bold fontSize={"2xl"} textAlign="justify" px={10}>
          Cuenta
        </Text>

        <Text bold fontSize={"lg"} px={10}>
          {nombre} {apellido}.
        </Text>
        <Text fontWeight={"semibold"} fontSize={"md"} px={10}>
          {correo}
        </Text>
      </Stack>

      <Box w="80%" alignSelf="center">
        <div style={stackContainerStyle}>
          <OpcionUser
            titulo="Ver Usuarios registrados"
            texto="Lista de todos los usuarios registrados"
            funcion={() => UserNav("/VerUsuariosAdmin")}
            icon={LiaUsersSolid}
          />

          {/* <OpcionUser
            titulo="Ver propiedades apartadas"
            texto="Seguimiento a propiedades apartadas"
            funcion={() => UserNav("/ApartadosAdmin")}
            icon={TbHomeExclamation}
          /> */}
          <OpcionUser
            titulo="Información personal"
            texto="Visualiza y edita tus datos personales"
            funcion={() => UserNav("/UserInfo")}
            icon={PiUserList}
          />

          <OpcionUser
            titulo="Agregar Inmueble"
            texto="Agrega nuevo inmueble"
            funcion={() => UserNav("/AgregarInmuebleAdmin")}
            icon={MdOutlineAddHomeWork}
          />

          <OpcionUser
            titulo="Ver Inmuebles registrados"
            texto="Revisa y edita inmuebles"
            funcion={() => UserNav("/VerInmueblesAdmin")}
            icon={TbHomeEdit}
          />
          <OpcionUser
            titulo="Agregar usuario"
            texto="Agrega Desarrolladora o Broker nuevo"
            funcion={() => UserNav("/AgregarUsuarioAdmin")}
            icon={HiOutlineUserAdd}
          />

          <OpcionUser
            titulo="Favoritos"
            texto="Ver inmuebes favoritos"
            funcion={() => UserNav("/Favoritos")}
            icon={TbHomeHeart}
          />
        </div>
        <LogoutConfirmationPopover onConfirm={handleSalir} />
      </Box>
    </View>
  );
};
export default AdminPanel;
