import { View, Text, Box, ZStack } from "native-base";
import React from "react";

const FotoPortada = ({ url, texto, posicion }) => {
  const imagenDeFondo = {
    backgroundImage: `url("${url}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: posicion,
    width: "100%", // Asegúrate de ajustar estas propiedades según tus necesidades
    height: "96", // Ajusta la altura según necesites
  };

  return (
    <View w="100%" style={imagenDeFondo}>
      <ZStack w="100%" h={{ base: 40, md: 96 }}>
        <Box w="100%" h={{ base: 40, md: 96 }} bg="#000" opacity={0.3} />
        <Text
          bold
          fontSize={["4xl", "4xl", "4xl", "6xl", "6xl"]}
          color="#fff"
          alignSelf="center"
          mt={{ base: 12, md: 40 }}
          textAlign="center"
          fontFamily="Baskerville"
          style={{
            textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
          }}
        >
          {texto}
        </Text>
      </ZStack>
    </View>
  );
};

export default FotoPortada;
