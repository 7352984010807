import React, { useState } from "react";
import {
  Divider,
  HStack,
  Text,
  View,
  ZStack,
  Image,
  Box,
  Pressable,
  VStack,
} from "native-base";
import InmuebleSlider from "../components/home/InmuebleSlider";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import ContactoFooter from "../components/ContactoFooter";

const Simulador = () => {
  const navigate = useNavigate();

  const handleClickMenu = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const Simula = ({ texto, titulo, imagen, nav }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    return (
      <Pressable
        w="50%"
        mb={16}
        onPress={() => handleClickMenu(nav)}
        onMouseEnter={() => setShowDescription(true)}
        onMouseLeave={() => setShowDescription(false)}
      >
        <ZStack w="100%" h={80} pb={10}>
          <Image
            source={{
              uri: imagen,
            }}
            alt="Alternate Text"
            w="100%"
            h={80}
          />
          <VStack
            bg="#fff"
            p="4"
            w="100%"
            shadow={8}
            justifyContent={"flex-end"}
            mt={showDescription ? 40 : 64}
          >
            <h3>{titulo}</h3>
            {showDescription && (
              <h4 style={{ fontWeight: "normal" }}>{texto}</h4>
            )}
          </VStack>
        </ZStack>
      </Pressable>
    );
  };

  return (
    <View>
      <h1
        style={{ fontFamily: "Graphik", color: "#404040", marginLeft: "2rem" }}
      >
        Simuladores de inversión inmobiliaria
      </h1>
      <Divider />
      <h2
        style={{
          fontFamily: "Graphik",
          color: "#404040",
          marginLeft: "2rem",
          alignSelf: "center",
          width: "80%",
        }}
      >
        ¿Quieres invertir en bienes raíces de alquiler? Se necesita la elección
        del inmueble más eficiente. Es simple, solo tienes que simular.
      </h2>
      <HStack space={10} w="80%" alignSelf="center" m={10}>
        <Simula
          titulo="Simulador de hipoteca"
          texto="Calcula tu préstamo ideal y proyecta tus pagos mensuales con precisión para tu futuro hogar."
          imagen="https://www.ocu.org/-/media/ocu/images/home/dinero/hipotecas/hipoteca_800x450.jpg"
          nav="/mortgage_simulator"
        />

        <Simula
          titulo="Simulador de rentabilidad"
          texto="Evalúa el potencial de inversión en propiedades y optimiza tus decisiones financieras con esta herramienta de análisis."
          imagen="https://www.ekon.es/wp-content/uploads/2021/11/rentabilidad-financiera.jpg"
          nav="/profitability_simulator"
        />
      </HStack>

      <HStack w="90%" alignSelf={"center"} space={10} justifyContent="center">
        <VStack w="45%">
          <Text>
            ¿Desea aprovechar los beneficios fiscales de la ley Pinel? En
            L'adresse Patrimonio, nuestra herramienta iSelección le ofrece la
            oportunidad de simular los ahorros fiscales y otros beneficios que
            podría obtener al invertir en una propiedad para alquilar:
          </Text>
          <Text bold pl={4} lineHeight={24}>
            - Reducción de impuestos
            {"\n"}- Distribución de su financiamiento
            {"\n"}- Ajuste de datos en tiempo real
            {"\n"}- Selección de propiedades adaptadas a su perfil
            {"\n"}- Recuperación de IVA
            {"\n"}- Amortización
          </Text>
        </VStack>

        <VStack w="45%">
          <Text>
            Para preparar su jubilación o aumentar sus ingresos, puede optar por
            invertir en una residencia de estudiantes con servicios o en una
            residencia de servicios para personas mayores. De esta manera, podrá
            beneficiarse de condiciones fiscales ventajosas relacionadas con el
            régimen de Alquiler Amueblado No Profesional.
            {"\n"} {"\n"}
            Para comprender mejor el mecanismo de estos dispositivos y descubrir
            los beneficios que pueden representar para usted, realice una
            simulación gratuita y sin compromiso.
            {"\n"} {"\n"}
            ATENCIÓN: los resultados de estas simulaciones no son contractuales
            y tienen un carácter estrictamente informativo. Solo un estudio
            detallado de su situación personal con uno de nuestros asesores
            validará sus posibilidades de inversión.
          </Text>
        </VStack>
      </HStack>

      {/* contacto */}
      <ContactoFooter />

      {/* Inmuebles */}
      <Box w="90%" my={10} alignSelf="center">
        <InmuebleSlider />
      </Box>
    </View>
  );
};

export default Simulador;
