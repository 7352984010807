import React from "react";
import { Menu, Pressable, Text, HamburgerIcon } from "native-base";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const MenuHamburger = () => {
  const navigate = useNavigate();
  const handleClickMenu = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  return (
    <Menu
      borderWidth={1}
      shadow={6}
      right={3}
      top={4}
      borderColor="muted.400"
      trigger={(triggerProps) => {
        return (
          <Pressable
            alignSelf={"center"}
            justifyContent="center"
            p={2}
            borderRadius={10}
            accessibilityLabel="More options menu"
            {...triggerProps}
          >
            <HamburgerIcon color={"#fff"} size={7} />
          </Pressable>
        );
      }}
    >
      {/* 
                - blog
                 */}

      {/* - Catálogo de promociones inmobiliarias  */}
      <Menu.Item onPress={() => handleClickMenu("/properties")}>
        <Text fontSize={"md"} fontFamily="Garet">
          Compra
        </Text>
      </Menu.Item>

      {/* posibilidades de financiación */}
      <Menu.Item onPress={() => handleClickMenu("/proyect")}>
        <Text fontSize={"md"} fontFamily="Garet">
          Vender
        </Text>
      </Menu.Item>

      <Menu.Item onPress={() => handleClickMenu("/investment")}>
        <Text fontSize={"md"} fontFamily="Garet">
          Invertir
        </Text>
      </Menu.Item>
      {/* - conversor de divisas */}
      <Menu.Item onPress={() => handleClickMenu("/converter")}>
        <Text fontSize={"md"} fontFamily="Garet">
          Conversor de divisas
        </Text>
      </Menu.Item>

      {/* servicios para inversores y agentes inmobiliarios */}
      {/* <Menu.Item onPress={() => handleClickMenu("/simulator")}>
        <Text fontSize={"md"} fontFamily="Garet">
         Simuladores
        </Text>
      </Menu.Item> */}

      {/* Testimonios y casos prácticos */}
      {/* <Menu.Item onPress={() => handleClickMenu("/proyect")}>
        <Text fontSize={"md"} fontFamily="Garet">
          {t("menu.tuProyecto")}
        </Text>
      </Menu.Item> */}

      {/*  - Quiénes somos (misión, visión, valores) */}
      <Menu.Item onPress={() => handleClickMenu("/about")}>
        <Text fontSize={"md"} fontFamily="Garet">
          Nosotros
        </Text>
      </Menu.Item>

      {/* - Contacto y asistencia */}
      <Menu.Item onPress={() => handleClickMenu("/contact")}>
        <Text fontSize={"md"} fontFamily="Garet">
          Contacto
        </Text>
      </Menu.Item>

      {/* - blog */}
      {/* <Menu.Item onPress={() => handleClickMenu("/blog")}>
        <Text fontSize={"md"} fontFamily="Garet">
          {t("menu.blog")}
        </Text>
      </Menu.Item> */}
    </Menu>
  );
};
export default MenuHamburger;
