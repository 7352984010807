import { Box, Image, Stack, VStack, View } from "native-base";
import FotoPortada from "../components/global/FotoPortada";

const Nosotros = () => {
  const Foto = ({ url }) => {
    return (
      <Image
        source={{
          uri: url,
        }}
        alt="Alternate Text"
        borderRadius={10}
        w="100%"
        h={72}
      />
    );
  };

  return (
    <View>
      <FotoPortada
        url="https://ladressepatrimoine.com/img/about1.jpg"
        texto="¿Quienes somos?"
        posicion="center"
      />

      <Box w="80%" alignSelf="center">
        <h3
          style={{
            fontFamily: "Graphik",
            fontWeight: "normal",
            lineHeight: 1.3,
            textAlign: "justify",
          }}
        >
          Somos una plataforma digital revolucionaria que va más allá de las
          transacciones inmobiliarias convencionales. En L'adresse Patrimoine,
          somos una comunidad global unida por la pasión por la innovación, la
          transparencia y la excelencia en el servicio al cliente.
        </h3>
      </Box>

      {/* mision */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
      >
        <Box w={{ base: "80%", md: "40%" }} alignSelf={"center"}>
          <Foto url="https://ladressepatrimoine.com/img/mision.jpg" />
        </Box>
        <VStack
          w={{ base: "90%", md: "60%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <h2 style={{ fontFamily: "Graphik" }}>Nuestra Misión:</h2>
          <Box w="90%" alignSelf="center">
            <h3
              style={{
                fontFamily: "Graphik",
                fontWeight: "normal",
                lineHeight: 1.3,
                textAlign: "justify",
              }}
            >
              Facilitar la conexión entre promotores inmobiliarios y una amplia
              red de inversores y agentes inmobiliarios internacionales es
              nuestra misión principal. En cada línea de código, en cada
              relación construida, buscamos simplificar y potenciar la
              experiencia de la inversión inmobiliaria.
            </h3>
          </Box>
        </VStack>
      </Stack>

      {/* mision */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
        mt={10}
      >
        <Box w={{ base: "80%", md: "40%" }} alignSelf={"center"}>
          <Foto url="https://ladressepatrimoine.com/img/vision.jpg" />
        </Box>
        <VStack
          w={{ base: "90%", md: "60%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <h2 style={{ fontFamily: "Graphik" }}>Nuestra Visión:</h2>
          <Box w="90%" alignSelf="center">
            <h3
              style={{
                fontFamily: "Graphik",
                fontWeight: "normal",
                lineHeight: 1.3,
                textAlign: "justify",
              }}
            >
              Imaginamos un mundo donde las oportunidades inmobiliarias
              trascienden fronteras, donde cada proyecto tiene el potencial de
              llegar a una audiencia global. Aspiramos a ser el
              mega-comercializador líder en el mundo, ofreciendo un valor
              inigualable a nuestros usuarios y liderando la transformación en
              el mercado inmobiliario global.
            </h3>
          </Box>
        </VStack>
      </Stack>

      {/* VALORES */}
      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
        my={10}
        space={3}
      >
        <Box w={{ base: "80%", md: "40%" }} alignSelf={"center"}>
          <Foto url="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c" />
        </Box>

        <VStack
          w={{ base: "85%", md: "60%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <h2 style={{ fontFamily: "Graphik", alignSelf: "center" }}>
            Nuestros Valores:
          </h2>
          <h3
            style={{
              fontFamily: "Graphik",
              fontWeight: "normal",
              lineHeight: 1.3,
              textAlign: "justify",
            }}
          >
            Nos regimos por la transparencia, la seguridad, la innovación
            continua y la excelencia en el servicio al cliente. Estos valores
            son más que principios; son los cimientos sobre los cuales
            construimos relaciones sólidas y duraderas.
          </h3>
        </VStack>
      </Stack>
    </View>
  );
};
export default Nosotros;
