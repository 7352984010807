import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Button, View } from "native-base";
import fetchPost from "../../../helper/fetchPost";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import URL from "../../../helper/baseURL";

const ApartadosBroker = () => {
  const { tipo, userId } = useUser();
  const [idUsuario, setIdUsuario] = useState(userId);
  const navigate = useNavigate();
  const [apartados, setApartados] = useState([]);
  const [loading, setLoading] = useState(false);

  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUsuario(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const id = idUsuario || (await getId());
    if (id) {
      setIdUsuario(id);
      await getData(id);
    } else {
      console.error("No se pudo obtener el ID de usuario");
    }
    setLoading(false);
  };

  const getData = async (id) => {
    const data = new FormData();
    data.append("broker", id);
    console.log("broker: ", id);

    const url = `${URL.BASE_URL}/leads/broker`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta prospectos: ", res);
    setApartados(res.data);
  };
  const handleVerInmueble = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  if (tipo !== "2") {
    return <Login />;
  }

  return (
    <View flex={1} mx={12} my={20}>
      <h1>Lista de prospectos (leads)</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#11003a" }}>
              <TableCell style={headerText}>ID</TableCell>
              <TableCell style={headerText}>Inmueble</TableCell>
              <TableCell style={headerText}>Prospecto</TableCell>
              <TableCell style={headerText}>Email</TableCell>
              <TableCell style={headerText}>Teléfono</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apartados.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  <Button
                    onPress={() =>
                      handleVerInmueble(
                        `/property/${row.inmueble}/${row.inmueble_nombre}`
                      )
                    }
                  >
                    {row.inmueble_nombre}
                  </Button>
                </TableCell>
                <TableCell>
                  {row.usuario_nombre + " " + row.usuario_apellido}
                </TableCell>
                <TableCell>{row.usuario_correo}</TableCell>
                <TableCell>
                  {row.usuario_lada + " " + row.usuario_telefono}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </View>
  );
};
export default ApartadosBroker;
