import React, { useEffect, useState } from "react";
import { Text, View, Stack, Heading, Box, VStack } from "native-base";
import { useUser } from "../../../helper/UserContext";
import { useNavigate } from "react-router";
import { PiUserList } from "react-icons/pi";
import LogoutConfirmationPopover from "../../../components/LogoutConfirmationPopover";
import OpcionUser from "../../../components/global/OpcionUser";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { TbHomeHeart, TbHomeCheck } from "react-icons/tb";
import CodigoReferencia from "../../../components/global/CodigoReferencia";
import { animateScroll as scroll } from "react-scroll";
import { LiaUsersSolid } from "react-icons/lia";
import { FaHouseUser } from "react-icons/fa6";
const DesarrolladoraPanel = () => {
  const { logout, nombre, apellido, correo, userId } = useUser();

  const navigate = useNavigate();

  const handleSalir = () => {
    // Lógica para el inicio de sesión
    logout();
    scroll.scrollToTop();
    navigate("/");
  };

  const UserNav = (nav) => {
    navigate(nav);
  };

  const stackContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alingItems: "center",
  };

  return (
    <View mt={[12, 12, 20, 24]}>
      <Heading bold fontSize={"2xl"} textAlign="center" mt={3}>
        BIENVENIDO
      </Heading>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        alignSelf={"center"}
        flex={1}
      >
        <VStack my={3}>
          <Text bold fontSize={"2xl"} textAlign="justify" px={10}>
            Cuenta
          </Text>

          <Text bold fontSize={"lg"} px={10}>
            {nombre} {apellido}.
          </Text>
          <Text fontWeight={"semibold"} fontSize={"md"} px={10}>
            {correo}
          </Text>
        </VStack>

        <CodigoReferencia id={userId} />
      </Stack>

      <Box w="80%" alignSelf="center">
        <div style={stackContainerStyle}>
          <OpcionUser
            titulo="Información de la empresa"
            texto="Visualiza y edita tus datos personales"
            funcion={() => UserNav("/UserInfo")}
            icon={PiUserList}
          />
          <OpcionUser
            titulo="Ver Brokers referidos"
            texto="Revisa los brokers registrados con tu código de referido"
            funcion={() => UserNav("/VerBrokersDesarrolladora")}
            icon={LiaUsersSolid}
          />

          <OpcionUser
            titulo="Prospectos (Leads)"
            texto="Ver y asigna prospectos a tus brokers"
            funcion={() => UserNav("/SolicitudesDesarrollo")}
            icon={FaHouseUser}
          />

          <OpcionUser
            titulo="Agregar Inmueble"
            texto="Agrega nuevo inmueble"
            funcion={() => UserNav("/AgregarInmuebleAdmin")}
            icon={MdOutlineAddHomeWork}
          />

          <OpcionUser
            titulo="Ver Inmuebles "
            texto="Ver tus inmuebles subidos"
            funcion={() => UserNav("/VerInmueblesDes")}
            icon={TbHomeCheck}
          />
          <OpcionUser
            titulo="Favoritos"
            texto="Ver inmuebes favoritos"
            funcion={() => UserNav("/Favoritos")}
            icon={TbHomeHeart}
          />

          {/* <OpcionUser
            titulo="Mi suscripción"
            texto="Información sobre mi suscripción"
            funcion={() => UserNav("/SuscripcionStatusDes")}
            icon={MdCardMembership}
          /> */}
        </div>
      </Box>

      <LogoutConfirmationPopover onConfirm={handleSalir} />
    </View>
  );
};
export default DesarrolladoraPanel;
