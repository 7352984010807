import React, { useState } from "react";
import { Text, Pressable, Image, VStack, HStack } from "native-base";
import { useNavigate } from "react-router-dom";
import URL from "../helper/baseURL";
import { useUser } from "../helper/UserContext";
import fetchPost from "../helper/fetchPost";
import useCustomToast from "./global/CustomToast";
import { secundario } from "../helper/colors";
import { animateScroll as scroll } from 'react-scroll';



const InmuebleComponent2 = ({ id, imageUri, titulo, lugar, duracion, precio, rooms, bath, guest, tipo }) => {
    const showToast = useCustomToast();


    const BASE_URL = URL.BASE_URL;
    const BASE_URL2 = "https://ladressepatrimoine.com/img/";
    const [isFavorite, setIsFavorite] = useState(false);
    //para navegar a otras vistas
    const navigate = useNavigate();
    const handleClick = (vista) => {
        scroll.scrollToTop();
        navigate(vista)
    };

    const { userId } = useUser();



    const handleClick2 = () => {
        navigate(`/detail/${id}/${titulo}`);
    };




    //logica de modal:
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <Pressable w="98%" borderColor={"#c4c4c4"} alignSelf="center"
            borderRadius={10} pb={2} bg={"#fff"} shadow={7} my={4} h={96}>
            <HStack w="100%" >

                {/* Imagen */}
                <Image h={96} w="50%" borderLeftRadius={10} source={{
                    uri: `${BASE_URL2}${imageUri}`
                }} alt="Alternate Text" />

                {/* Titulo */}

                <VStack w="50%" p={3} space={2} >
                    <Text fontFamily="Baskerville" fontSize={"2xl"} ml={3} >
                        {titulo}
                    </Text>
                    {/* <Text bold fontSize="lg" color="muted.700" fontFamily={"Garet"} >
                        Departamento
                    </Text> */}
                    <Text bold fontSize="lg" color="muted.700" fontFamily={"Garet"} >
                        Playa del Carmen
                    </Text>

                    <Text bold fontSize="lg" color="muted.700" fontFamily={"Garet"} >
                        Precio (a partir de):{" "}
                        <Text fontWeight={"normal"}>{precio} MXN</Text>
                    </Text>

                    {/* <Text bold fontSize="lg" color="muted.700" fontFamily={"Garet"} >
                        Rentabilidad:{" "}
                        <Text fontWeight={"normal"}>3,70%</Text>
                    </Text> */}
                    <Text bold fontSize="lg" color="muted.700" fontFamily={"Garet"} >
                        Tipo:{" "}
                        <Text fontWeight={"normal"}>{tipo}</Text>
                    </Text>



                    <Pressable onPress={() => navigate("/p/1/test")}
                        px={2} w={40} mx={2} mt={4} h={10} bg={secundario}
                        shadow={5} borderRadius={5} justifyContent="center" alignItems="center">
                        <Text py={2} px={4} fontSize="lg" textAlign="center" color="#fff" style={{ fontFamily: 'Garet' }} >
                            Leer más
                        </Text>
                    </Pressable>





                </VStack>








            </HStack>
        </Pressable>
    );
};

export default InmuebleComponent2;
