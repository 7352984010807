import { Button, HStack, Pressable, View } from "native-base";
import React, { useState, useEffect } from "react";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import fetchPost from "../../../helper/fetchPost";
import URL from "../../../helper/baseURL";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { Spinner } from "native-base";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

const VerInmueblesAdmin = () => {
  const { tipo, userId } = useUser();
  const [inmuebles, setInmuebles] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleNav = (id, nombre) => {
    navigate(`/property/${id}/${nombre}`);
  };

  const getInmuebles = async () => {
    const url = `${URL.BASE_URL}/verInmueblesAdmin`;
    const options = {
      method: "POST",
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.success) {
      setInmuebles(res.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInmuebles();
  }, []);

  const handleEstadoChange = async (id, status) => {
    // Lógica para cambiar el estado del inmueble

    const data = new FormData();
    data.append("status", status);
    data.append("id_inmueble", id);
    const url = `${URL.BASE_URL}/cambiarStatusInmuebleAdmin`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.status) {
      getInmuebles();
    } else {
      window.alert(res.message);
    }
  };

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  if (tipo !== "0") {
    return <Login />;
  }

  return (
    <>
      {isLoading ? (
        <Spinner size="xl" py={20} alignSelf={"center"} />
      ) : (
        <View mx={10} flex={1} my={16}>
          <h1 style={{ textAlign: "center" }}>Inmuebles registrados</h1>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#11003a" }}>
                  <TableCell style={headerText}>ID</TableCell>
                  <TableCell style={headerText}>Desarrolladora</TableCell>
                  <TableCell style={headerText}>Titulo</TableCell>
                  <TableCell style={headerText}>Direccion</TableCell>
                  <TableCell style={headerText}>Precio</TableCell>
                  <TableCell style={headerText}>Unidades</TableCell>
                  <TableCell style={headerText}>Habilitado</TableCell>
                  <TableCell style={headerText}>Link</TableCell>

                  {/* <TableCell style={headerText}>Acciones</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {inmuebles.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.nombre_usuario}</TableCell>
                    <TableCell>{row.nombre}</TableCell>
                    <TableCell>{row.ubicacion}</TableCell>
                    <TableCell>{row.precio}</TableCell>
                    <TableCell>{row.unidades}</TableCell>
                    <TableCell>
                      {/* {row.status} */}
                      <Switch
                        checked={row.status === "1"}
                        onChange={() => handleEstadoChange(row.id, row.status)}
                        color="success"
                      />
                    </TableCell>
                    <TableCell>
                      <Button onPress={() => handleNav(row.id, row.nombre)}>
                        Ver Inmueble
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <HStack space={4}>
                        <Pressable
                          bg="#16a34a"
                          p={2}
                          borderRadius={10}
                          shadow={3}
                          onPress={() => handleNav(row.id)}
                        >
                          <FaEdit color="#fff" size={"18px"} />
                        </Pressable>
                        <Pressable
                          bg="#e11d48"
                          p={2}
                          borderRadius={10}
                          shadow={3}
                        >
                          <FaRegTrashAlt color="#fff" size={"18px"} />
                        </Pressable>
                      </HStack>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      )}
    </>
  );
};
export default VerInmueblesAdmin;
