import { Text, Stack, VStack, Button, HStack, Image } from "native-base";
import React from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
const InvitacionRegistro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    scroll.scrollToTop();
    navigate("/login");
  };

  const Imagen = ({ url }) => {
    return (
      <Image
        borderRadius={10}
        source={{
          uri: url,
        }}
        alt="Alternate Text"
        w={{ base: 24, lg: 56 }}
        h={{ base: 24, lg: 56 }}
      />
    );
  };

  const Bandera = ({ bandera }) => {
    return (
      <Image
        source={{
          uri: `https://ladressepatrimoine.com/img/${bandera}`,
        }}
        alt="Alternate Text"
        size="xs"
      />
    );
  };

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      py={2}
      bg="#fff"
      w="100%"
      space={6}
    >
      <VStack
        w={{ base: "90%", lg: "40%" }}
        p={4}
        space={4}
        alignSelf={"center"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={"xl"} bold fontFamily={"Graphik"} textAlign={"center"}>
          ¡Ten acceso exclusivo a los mejores desarrollos inmobiliarios en
          distintos países!
        </Text>
        <HStack
          alignSelf={"center"}
          space={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Bandera bandera="mexico.png" />
          <Bandera bandera="usa.png" />
          <Bandera bandera="france.png" />
          <Bandera bandera="spain.png" />
        </HStack>
        <Text fontSize={"lg"} fontFamily={"Garet"} textAlign={"center"}>
          Inicia sesión para una experiencia personalizada.
        </Text>
        <Button
          px={10}
          size="lg"
          variant="outline"
          onPress={() => handleClick()}
          _text={{ fontWeight: "bold", fontFamily: "Graphik" }}
        >
          Iniciar sesión
        </Button>
      </VStack>

      <HStack space={{ base: 1, md: 4 }} mx={4}>
        <Imagen url="https://ladressepatrimoine.com/img/img97.jpg" />
        <Imagen url="https://ladressepatrimoine.com/img/01galeria00.webp" />
        <Imagen url="https://ladressepatrimoine.com/img/01portada.webp" />
      </HStack>
    </Stack>
  );
};

export default InvitacionRegistro;
