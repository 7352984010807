import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { Center } from "native-base";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const initialPosition = {
  lat: 21.1213982, // Puedes cambiar estas coordenadas iniciales
  lng: -86.8564038,
};

function MapComponent({ onMapLinkChange, onLongitudChange, onLatitudChange }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDr8uHCyUhZYtzQW6Gwy0_xrV9wZSRNeHU",
  });

  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(initialPosition);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
    map.setCenter(initialPosition);
    map.setZoom(10); // Aquí puedes ajustar el nivel de zoom inicial
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    const newLink = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d466.85103980175745!2d${newLng}!3d${newLat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1715108298305!5m2!1ses!2smx&markers=${newLat},${newLng}`;

    // Llamar a la función del componente padre
    onMapLinkChange(newLink);
    onLatitudChange(newLat);
    onLongitudChange(newLng);
  };

  return (
    <Center>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerPosition}
          zoom={10} // Y también aquí si quieres un zoom inicial diferente
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerF
            position={markerPosition}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        </GoogleMap>
      ) : (
        <></>
      )}

      <Center>
        <input type="text" value={"Latitud: " + markerPosition.lat} readOnly />
        <input type="text" value={"Longitud: " + markerPosition.lng} readOnly />
      </Center>
    </Center>
  );
}

export default React.memo(MapComponent);
