import { Input, VStack, Text, Pressable, Flex, Box } from "native-base";
import React from "react";
import { secundario } from "../../helper/colors";
import { FaSearch } from "react-icons/fa";

const Buscador = () => {
  const imagenDeFondo = {
    backgroundImage: `url("https://ladressepatrimoine.com/img/portada.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    width: "100%",
    height: "96",
  };

  const Titulo = ({ texto }) => {
    return (
      <Text
        bold
        color="#fff"
        fontFamily={"Graphik"}
        fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
        mx={{ base: 6, md: 20 }}
        style={{
          textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
        }}
      >
        {texto}
      </Text>
    );
  };

  return (
    <VStack
      justifyContent={"center"}
      alignItems={{ base: "flex-start", md: "flex-start" }}
      bg={secundario}
      style={imagenDeFondo}
      py={{ base: 10, md: 10 }}
      pt={20}
    >
      <Titulo texto="Forma parte del futuro" />

      <Titulo texto="de la inversión inmobiliaria" />

      <Box
        bg="#fff"
        my={10}
        mx={{ base: 4, sm: 10, md: 20 }}
        w={{ base: "60%", md: "35%" }}
        borderRadius={40}
      >
        <Input
          bg="#fff"
          //color={"#fff"}
          variant="rounded"
          placeholder="Ingresa una direccion, colonia, ciudad, C.P."
          alignSelf={"flex-start"}
          w="100%"
          InputRightElement={
            <Pressable p={4} mr={2}>
              <FaSearch color={secundario} />
            </Pressable>
          }
        />
      </Box>
    </VStack>
  );
};

export default Buscador;
