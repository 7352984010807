import React, { useState } from "react";
import {
  HStack,
  VStack,
  Image,
  Text,
  Box,
  Select,
  CheckIcon,
  Button,
} from "native-base";
import SwiperHome from "./SwiperHome";
import { Range, getTrackBackground } from "react-range";

const InicioComponent = () => {
  const [service, setService] = React.useState("");
  const [service2, setService2] = React.useState("");

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);

  const handleValueChange = ([min, max]) => {
    setMinPrice(min);
    setMaxPrice(max);
  };

  const STEP = 10000;
  const MIN = 0;
  const MAX = 600000;

  // Añade un valor predeterminado para rtl directamente en los argumentos de la función.
  const PrecioSlide = ({ rtl = false }) => {
    const [values, setValues] = useState([40000, 350000]);
    // Función para formatear el número a un string con formato de moneda sin decimales
    const formatCurrency = (value) => {
      // Primero, convertimos el número a un formato de moneda genérico sin símbolo de moneda.
      let formatted = value.toLocaleString("fr-FR", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

      // Luego, reemplazamos los separadores estándar (en este caso, comas o puntos) por espacios.
      formatted = formatted.replace(/[.,]/g, " ");

      // Finalmente, agregamos el símbolo de dólar al inicio.
      return `$${formatted}`;
    };

    return (
      <VStack>
        <Text mb={8} fontFamily={"Graphik"}>
          Precio (EUR):
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            rtl={rtl}
            onChange={(values) => setValues(values)}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values,
                      colors: ["#ccc", "#548BF4", "#ccc"],
                      min: MIN,
                      max: MAX,
                      rtl: rtl,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ index, props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  borderRadius: "4px",
                  backgroundColor: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-28px",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "14px",
                    fontFamily: "Garet",
                    padding: "4px",
                    borderRadius: "4px",
                    backgroundColor: "#548BF4",
                  }}
                >
                  {formatCurrency(values[index])}
                </div>
                <div
                  style={{
                    height: "16px",
                    width: "5px",
                    backgroundColor: isDragged ? "#548BF4" : "#CCC",
                  }}
                />
              </div>
            )}
          />
        </div>

        <Button shadow={5} py={2} mt={5} px={4} w={40} alignSelf="flex-end">
          Buscar
        </Button>
      </VStack>
    );
  };

  return (
    <HStack flex={1}>
      <VStack
        w={{
          base: "100%",
          lg: "40%",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Image
          alignSelf="center"
          my={5}
          source={{
            uri: `https://ladressepatrimoine.com/img/logo2.png`,
          }}
          alt="Alternate Text"
          w={56}
          h={24}
          resizeMode="cover"
        />
        <Text fontSize="lg" flex={1} fontFamily="Baskervile">
          Especialista en inversiones inmobiliarias
        </Text>
        <Text alignSelf="center" fontFamily="Garet" py={2}>
          Busca inmuebles para inversión
        </Text>

        <HStack
          w="100%"
          space={5}
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
        >
          <Box w="40%">
            <Select
              selectedValue={service}
              accessibilityLabel="Tipo de inmueble"
              placeholder="Tipo de inmueble"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => setService(itemValue)}
            >
              <Select.Item label="Casas" value="ux" />
              <Select.Item label="Departamentos" value="web" />
              <Select.Item label="Condominios" value="cross" />
            </Select>
          </Box>

          <Box w="40%">
            <Select
              selectedValue={service2}
              accessibilityLabel="Selecciona región"
              placeholder="Regiones"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => setService2(itemValue)}
            >
              <Select.Item label="Cancún" value="ux" />
              <Select.Item label="Tulum" value="web" />
              <Select.Item label="Playa del Carmen" value="cross" />
            </Select>
          </Box>
        </HStack>

        <Box w="75%" py={5} my={5} alignSelf="center">
          <PrecioSlide />
        </Box>
      </VStack>

      <Box w="60%" alignSelf="flex-end" display={{ base: "none", lg: "flex" }}>
        <SwiperHome />
      </Box>
    </HStack>
  );
};
export default InicioComponent;
