import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import { Button, Center, Modal, VStack, View } from "native-base";
import fetchPost from "../../../helper/fetchPost";
import { useUser } from "../../../helper/UserContext";
import Login from "../../Login";
import Loader from "../../../components/Loader";
import URL from "../../../helper/baseURL";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const SolicitudesDesarrollo = () => {
  const navigate = useNavigate();
  const { tipo, userId } = useUser();
  const [idUsuario, setIdUsuario] = useState(userId);
  const [dataBrokers, setDataBrokers] = useState([]);
  const [apartados, setApartados] = useState([]);

  const headerText = {
    color: "white",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
  };

  const getId = async () => {
    try {
      const value = await AsyncStorage.getItem("userId");
      if (value !== null) {
        console.log("async id: ", value);
        setIdUsuario(value);
        return value;
        // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const id = idUsuario || (await getId());
    if (id) {
      setIdUsuario(id);
      await Promise.all([getData(id), getDataBrokers(id)]);
    } else {
      console.error("No se pudo obtener el ID de usuario");
    }
    setLoading(false);
  };

  const getData = async (id) => {
    const data = new FormData();
    data.append("desarrolladora", id);
    console.log("desarrolladora: ", id);

    const url = `${URL.BASE_URL}/leads/desarrolladora`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta prospectos: ", res);
    setApartados(res.data);
  };

  const getDataBrokers = async (id) => {
    const data = new FormData();
    data.append("id_usuario", id);

    const url = `${URL.BASE_URL}/desarrolladora/verBrokers`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta brokers: ", res);
    setDataBrokers(res.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);

  const handleVerBroker = (id, nombre) => {
    scroll.scrollToTop();
    navigate(`/userinformation/${id}/${nombre}/`);
  };
  const handleVerInmueble = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const [inmueble, setInmueble] = useState(null);

  const handleAsignarBrokerModal = (inmueble) => {
    setInmueble(inmueble);
    console.log("Asignar broker inmueble:", inmueble);
    setShowModal(true);
  };

  const handleAsignarBroker = async (broker, inmueble) => {
    console.log("Asignar inmueble: ", inmueble);
    console.log("Asignar broker: ", broker);

    const data = new FormData();
    data.append("broker", broker);
    data.append("id", inmueble);

    const url = `${URL.BASE_URL}/leads/asignarBroker`;
    const options = {
      method: "POST",
      body: data,
    };
    try {
      const res = await fetchPost(url, options);
      console.log("respuesta broker asignado: ", res);
      window.alert(res.message);
      await fetchData(); // Recargar todos los datos después de asignar el broker
      setShowModal(false);
    } catch (error) {
      console.error("Error al asignar broker:", error);
      window.alert(
        "Hubo un error al asignar el broker. Por favor, intente de nuevo."
      );
    }
  };

  const [showModal, setShowModal] = useState(false);

  if (tipo !== "1") {
    return <Login />;
  }

  return (
    <View mx={12} flex={1} alignSelf={"center"} my={20}>
      <h1 style={{ textAlign: "center" }}>Solicitudes recibidas</h1>

      {apartados === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#11003a" }}>
                <TableCell style={headerText}>ID</TableCell>
                <TableCell style={headerText}>Inmueble</TableCell>
                <TableCell style={headerText}>Prospecto</TableCell>
                <TableCell style={headerText}>Email</TableCell>
                <TableCell style={headerText}>Teléfono</TableCell>
                <TableCell style={headerText}>Broker</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apartados.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <Button
                      onPress={() =>
                        handleVerInmueble(
                          `/property/${row.inmueble}/${row.inmueble_nombre}`
                        )
                      }
                    >
                      {row.inmueble_nombre}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {row.prospecto_nombre + " " + row.prospecto_apellido}
                  </TableCell>
                  <TableCell>{row.prospecto_correo}</TableCell>
                  <TableCell>{row.telefono}</TableCell>
                  <TableCell>
                    {row.broker_nombre === null ? (
                      <Button onPress={() => handleAsignarBrokerModal(row.id)}>
                        Asignar Broker
                      </Button>
                    ) : (
                      <Button
                        onPress={() =>
                          handleVerBroker(row.broker, row.broker_nombre)
                        }
                      >
                        {row.broker_nombre + " " + row.broker_apellido}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* MODAL SELECCIONAR BROKERS */}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Selecciona Broker para asignar prospecto</Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#11003a" }}>
                    <TableCell style={headerText}>ID</TableCell>
                    <TableCell style={headerText}>Nombre</TableCell>
                    <TableCell style={headerText}>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataBrokers.map((broker) => (
                    <TableRow key={broker.ID}>
                      <TableCell>{broker.ID}</TableCell>
                      <TableCell>{`${broker.nombre} ${broker.apellidoP} ${
                        broker.apellidoM || ""
                      }`}</TableCell>
                      <TableCell>
                        <Button
                          onPress={() =>
                            handleAsignarBroker(broker.ID, inmueble)
                          }
                          size="sm"
                        >
                          Asignar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  );
};
export default SolicitudesDesarrollo;
