import { View, Text, Stack, Center } from "native-base";
import ContactoSimple from "../components/ContactoSimple";
import FotoPortada from "../components/global/FotoPortada";

const TerminosCondiciones = () => {
  return (
    <View w="100%" bg="#dadada">
      <FotoPortada
        url="https://mareaproperties.com.mx/imagenfr/foto1.jpg"
        texto="Términos y condiciones"
      />

      <Stack w="100%" direction={["column", "column", "row", "row", "row"]}>
        <Stack
          direction={"column"}
          w={["90%", "90%", "50%", "50%", "50%"]}
          mx="5%"
          my={10}
          alignSelf="center"
          justifyContent="center"
        >
          <Text bold fontSize="xl">
            Términos y condiciones
          </Text>
          <Text fontSize="2xl" bold>
            Términos y condiciones de L'adresse patrimoine
          </Text>

          {/* Sección Introducción */}
          <Text>Términos y condiciones</Text>
        </Stack>

        <Center direction={"column"} w={["90%", "90%", "40%", "40%", "40%"]}>
          <ContactoSimple />
        </Center>
      </Stack>
    </View>
  );
};
export default TerminosCondiciones;
