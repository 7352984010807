// ModeloInversion.jsx
import { View, Box, VStack, Stack, Image } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import ContactoFooter from "../components/ContactoFooter";

const ModeloInversion = () => {
  return (
    <View>
      <FotoPortada
        url="https://ladressepatrimoine.com/img/coin.jpg"
        texto="Modelo de inversión"
        posicion="top"
      />

      <Box w="80%" alignSelf="center">
        <h3
          style={{
            fontWeight: "normal",
            fontFamily: "Graphik",
            textAlign: "justify",
            lineHeight: 1.4,
          }}
        >
          Bienvenidos a L'adresse Patrimoine, tu plataforma especializada en
          bienes raíces, diseñada tanto para desarrolladores inmobiliarios como
          para brokers. En L'adresse Patrimoine, nos esforzamos por ofrecer una
          experiencia única y eficiente, conectando a desarrolladores con
          oportunidades de inversión y a brokers con propiedades de alta calidad
          para satisfacer las necesidades de sus clientes. A continuación,
          presentamos nuestro modelo de negocio, enfocado en brindar valor tanto
          a los desarrolladores como a los brokers inmobiliarios.
        </h3>
      </Box>

      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
      >
        <Box
          w={{ base: "80%", md: "40%" }}
          alignSelf={{ base: "center", md: "flex=start" }}
        >
          <Image
            source={{
              uri: "https://ladressepatrimoine.com/img/desarrolladores.jpg",
            }}
            alt="Alternate Text"
            w={"100%"}
            borderRadius={10}
            h={{ base: 56, md: 96 }}
          />
        </Box>
        <VStack w={{ base: "100%", md: "60%" }}>
          <h2
            style={{
              fontFamily: "Graphik",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            Maximiza tu Potencial con L'adresse Patrimoine
          </h2>
          <Box w="85%" alignSelf="center">
            <h3
              style={{
                fontWeight: "normal",
                fontFamily: "Graphik",
                textAlign: "justify",
                lineHeight: 1.4,
              }}
            >
              En L'adresse Patrimoine entendemos las necesidades únicas de los
              desarrolladores inmobiliarios. Nuestra plataforma proporciona una
              oportunidad excepcional para mostrar tus proyectos y llegar a una
              amplia base de clientes potenciales. Al unirte a nuestra red,
              podrás:
              <br />
              <strong>Visibilidad Mejorada:</strong> Presenta tus desarrollos a
              una audiencia global de inversores y compradores interesados en
              propiedades exclusivas. <br />
              <strong>Colaboración Eficiente:</strong> Conecta con brokers de
              confianza para agilizar el proceso de venta y maximizar tu retorno
              de inversión. <br />
              <strong>Asesoramiento Profesional:</strong> Recibe asesoramiento
              experto y apoyo en cada etapa del proceso, desde la planificación
              hasta la venta final. <br />
              En L'adresse Patrimoine, estamos comprometidos a impulsar el éxito
              de tus proyectos inmobiliarios y a facilitar tu crecimiento en el
              mercado.
            </h3>
          </Box>
        </VStack>
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        w="90%"
        alignSelf="center"
      >
        <VStack
          w="85%"
          alignSelf={"center"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <h2
            style={{
              fontFamily: "Graphik",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            Potencia tus Negocios con L'adresse Patrimoine
          </h2>
          <h3
            style={{
              fontWeight: "normal",
              fontFamily: "Graphik",
              textAlign: "justify",
              lineHeight: 1.4,
            }}
          >
            Como broker inmobiliario, sabes la importancia de acceder a una
            amplia gama de propiedades de calidad para satisfacer las
            necesidades de tus clientes. En L'adresse Patrimoine, ofrecemos una
            plataforma integral diseñada para potenciar tu negocio y facilitar
            tu éxito. Al unirte a nuestra red, disfrutarás de beneficios tales
            como:
            <br />
            <strong>Acceso a Propiedades Exclusivas:</strong> Explora una
            selección cuidadosamente curada de propiedades de alta calidad,
            disponibles para su venta inmediata. <br />
            <strong>Comisiones Atractivas:</strong> Gana comisiones competitivas
            al conectar a tus clientes con las propiedades que mejor se adapten
            a sus necesidades. <br />
            <strong>Apoyo Personalizado:</strong> Recibe soporte dedicado de
            nuestro equipo experto para ayudarte en cada transacción y
            garantizar una experiencia sin contratiempos. <br />
            <strong>Networking Estratégico:</strong> Conecta con desarrolladores
            y otros profesionales del sector para explorar nuevas oportunidades
            de negocio y establecer relaciones duraderas.
            <br />
            <br />
            En L'adresse Patrimoine, estamos comprometidos a ser tu aliado
            confiable en el mercado inmobiliario, proporcionándote las
            herramientas y recursos necesarios para alcanzar tus metas
            comerciales con éxito.
          </h3>
        </VStack>
      </Stack>

      <ContactoFooter />
    </View>
  );
};

export default ModeloInversion;
