// Inmuebles.jsx
import { View } from "native-base";
import FotoPortada from "../components/global/FotoPortada";
import FlatListInmuebles from "../components/FlatListInmuebles";
import ContactoFooter from "../components/ContactoFooter";

const Inmuebles = () => {
  return (
    <View>
      <FotoPortada
        url="https://ladressepatrimoine.com//img/inmuebles.jpg"
        texto="Inmuebles"
        posicion="center"
      />

      <View alignContent={"center"} pt={12} bg="#dadada" w="100%">
        <FlatListInmuebles />
      </View>

      <ContactoFooter />
    </View>
  );
};

export default Inmuebles;
