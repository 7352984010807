import { VStack, Image, Text, Stack } from "native-base";
import React from "react";
import { primario } from "../../helper/colors";

const InmuebleFav = () => {
  return (
    <VStack>
      <Image
        source={{
          uri: "https://belehu.com/wp-content/uploads/2024/02/BELEHU_FACH__23-08-09__03__-1.webp",
        }}
        alt="Alternate Text"
        w="100%"
        h={40}
      />
      <Stack
        direction={{
          sm: "column",
          md: "row",
        }}
        w="100%"
      >
        <VStack
          w={{
            sm: "100%",
            md: "50%",
          }}
        >
          <VStack borderLeftWidth={6} borderColor={primario}>
            <Text bold ml={2} fontFamily={"Garet"} fontSize={"3xl"} mt={2}>
              Nuestra residencia
            </Text>
            <Text ml={2} mb={2} fontFamily={"Garet"} fontSize={"3xl"}>
              Preferida
            </Text>
          </VStack>
          <Text bold ml={2} fontSize={"lg"} fontFamily={"Garet"}>
            Playa del Carmen
          </Text>
          <Text bold ml={2} fontSize={"lg"} fontFamily={"Garet"}>
            Belehu
          </Text>
          <Text ml={2} fontSize={"lg"} fontFamily={"Garet"}>
            Precio (a partir de): <Text bold>2,800,000 MXN</Text>
          </Text>
        </VStack>

        <VStack
          w={{
            sm: "100%",
            md: "50%",
          }}
          px={3}
          mt={[5, 5, 0, 0, 0]}
        >
          <Text
            bold
            borderLeftWidth={6}
            pl={3}
            fontSize={{ base: "lg", md: "2xl" }}
            fontFamily={"Garet"}
          >
            Invertir en BELEHU entre la naturaleza y la vida urbana.
          </Text>
          <Text
            alignSelf="center"
            w="90%"
            fontSize="sm"
            mt={4}
            fontFamily={"Garet"}
          >
            Tu sueño de vivir en el paraíso se hace realidad en Belehu. Disfruta
            de la belleza natural de Playa del Carmen y de las comodidades de un
            desarrollo inmobiliario excepcional.
          </Text>
        </VStack>
      </Stack>
    </VStack>
  );
};
export default InmuebleFav;
