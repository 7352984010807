import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { HStack, Pressable, Center, Text, VStack } from "native-base";
import { animateScroll as scroll } from "react-scroll";
import { useUser } from "../helper/UserContext";
import { FaUserAlt } from "react-icons/fa";
import MenuHamburger from "./header/MenuHamburger";
import { secundario } from "../helper/colors";
import LenguajeSelector from "./header/LenguajeSelector";

const Header = () => {
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const { userId } = useUser();

  const handleClickMenu = (vista) => {
    scroll.scrollToTop();
    navigate(vista);
  };

  const [translateWidgetLoaded, setTranslateWidgetLoaded] = useState(false);

  useEffect(() => {
    if (!translateWidgetLoaded) {
      const googleTranslateScript = document.createElement("script");
      googleTranslateScript.type = "text/javascript";
      googleTranslateScript.async = true;
      googleTranslateScript.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      document.body.appendChild(googleTranslateScript);

      window.googleTranslateElementInit = function () {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "es",
            includedLanguages: "en,es,fr",
          },
          "google_translate_element"
        );
      };
    }
  }, [translateWidgetLoaded]);

  const headerStyle = {
    // position: 'fixed',
    top: 0, // Aplicando el estado de paddingTop aquí
    left: 0,
    right: 0,
    zIndex: 99,
  };

  const ButtonMenu = ({ link, texto, border }) => {
    return (
      <Pressable
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        onPress={() => handleClickMenu(link)}
        alignSelf={"center"}
        display={{ base: "none", lg: "flex" }}
        p={0.5}
        flex={1}
        justifyContent="center"
        borderWidth={border}
      >
        <Text
          color={"#fff"}
          flex="1"
          fontSize={["2xs", "2xs", "2xs", "xs", "md"]}
          textAlign="center"
          py={2}
          bold
          fontFamily={"Graphik"}
        >
          {texto}
        </Text>
      </Pressable>
    );
  };

  return (
    <>
      <header ref={headerRef} style={headerStyle}>
        <nav>
          <HStack
            // borderColor={['#ff0', '#0f0', '#00f', '#0f0', '#f50']}
            // borderWidth={3}
            bg={secundario}
            zIndex={1}
            pt={0}
            h={20}
            w="100%"
            alignItems="center"
            justifyContent="center"
            space={1}
          >
            <Center display={{ base: "flex", lg: "none" }} ml={4} mr={-4}>
              <MenuHamburger />
            </Center>
            {/* Botones de Menú a la Izquierda */}
            <HStack
              justifyContent="flex-start"
              display={{ base: "none", lg: "flex" }}
              w="35%"
            >
              {/* <DestinosMenu /> */}
              {/* boton destinos */}
              <ButtonMenu link="/properties" texto="Compra" />
              <ButtonMenu link="/investment" texto="Invertir" />
              <ButtonMenu link="/proyect" texto="Vender" />
              <ButtonMenu link="/contact" texto="Contacto" />
            </HStack>

            {/* Imagen LOGO Centrada */}

            <Pressable
              onPress={() => handleClickMenu("/")}
              mx={{ base: 1, md: 4 }}
              alignSelf={"center"}
              w={[40, 48, 56, 64, 72]}
            >
              <img
                src="https://ladressepatrimoine.com/img/logo-pngblanco.png"
                alt="Marea Properties"
                style={{
                  width: "100px",
                  height:
                    "auto" /* Ajusta la altura máxima según sea necesario */,
                  objectFit: "contain",
                  alignSelf: "center",
                }}
              />
            </Pressable>

            {/* Botones de Menú y Contacto a la Derecha */}

            <HStack
              pr={2}
              justifyContent="flex-end"
              flex={1}
              space={{ base: 2, md: 4 }}
            >
              <ButtonMenu link="/converter" texto="Convertidor de divisas" />
              <ButtonMenu link="/about" texto="Nosotros" />

              <Center>
                <LenguajeSelector />
              </Center>

              <Pressable
                onPress={() =>
                  userId ? handleClickMenu("/login") : handleClickMenu("/login")
                }
                p={2}
                alignItems="center"
                justifyContent="center"
              >
                <VStack alignItems={"center"}>
                  <FaUserAlt size={24} color={"#dadada"} />
                  <Text
                    color={"#fff"}
                    flex="1"
                    fontSize={["2xs", "2xs", "2xs", "xs", "md"]}
                    textAlign="center"
                    py={2}
                    bold
                    fontFamily={"Graphik"}
                  >
                    Cuenta
                  </Text>
                </VStack>
              </Pressable>
            </HStack>
          </HStack>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default Header;
