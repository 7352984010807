import { FlatList, Flex, HStack, Spinner, Text } from "native-base";
import { useEffect, useState } from "react";
import InmuebleComponent from "../components/InmuebleComponent";
import fetchPost from "../helper/fetchPost";
import URL from "../helper/baseURL";
import { useUser } from "../helper/UserContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TbHomeHeart } from "react-icons/tb";

const FlatListInmuebles = () => {
  const { userId } = useUser();

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("userID");
      if (value !== null) {
        setId(value);
        getInmuebles(value); // value previously stored
      }
    } catch (e) {
      // error reading value
    }
  };

  // Datos estáticos para cargar en el FlatList
  const datosEstaticos = [
    {
      ID: "1",
      fotoportada: "BELEHU.webp",
      nombre: "BELEHU",
      ubicacion: "Tulum",
      precio: "2,800,000",
      rooms: "2",
      bath: "1",
      guest: "2",
      tipo: "Departamento",
    },
    {
      ID: "2",
      fotoportada: "oceantulum.jpg",
      nombre: "Ocean Tulum",
      ubicacion: "Cancún",
      precio: "2,300,000",
      rooms: "2",
      bath: "1",
      guest: "2",
      tipo: "Lotes residenciales",
    },
  ];

  const [viajes, setViajes] = useState(datosEstaticos);

  // Código para determinar el número de columnas basado en el tamaño de la pantalla
  const breakpoints = {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  };

  const numColumns =
    window.innerWidth < breakpoints.sm
      ? 1
      : window.innerWidth < breakpoints.md
      ? 1
      : window.innerWidth < breakpoints.lg
      ? 1
      : window.innerWidth < breakpoints.xl
      ? 2
      : 2; // Puedes ajustar los valores según tus necesidades

  const [inmuebles, setInmuebles] = useState(datosEstaticos);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(userId);

  const getInmuebles = async (id) => {
    const data = new FormData();
    console.log("user id fav: ", id);
    data.append("id_usuario", id);

    const url = `${URL.BASE_URL}/favoritos/ver`;
    const options = {
      method: "POST",
      body: data,
    };
    const res = await fetchPost(url, options);
    console.log("respuesta inmuebles", res);
    if (res.status) {
      setInmuebles(res.favoritos);
      setIsLoading(false);
    } else {
      setInmuebles(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!userId) {
      getData();
    } else {
      getInmuebles(userId);
    }
  }, []);

  const VistaCarga = () => {
    if (isLoading) {
      return (
        <Flex my={20}>
          <Spinner size="lg" my={20} />
          <Text textAlign={"center"} bold fontSize={"xl"}>
            {" "}
            Cargando favoritos. . .
          </Text>
        </Flex>
      );
    }
    if (!inmuebles) {
      return (
        <Flex mt={40} mb={96}>
          <HStack
            alignSelf={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            space={8}
            p={12}
          >
            <TbHomeHeart size="3em" />
            <Text textAlign={"center"} bold fontSize={"2xl"}>
              {" "}
              No tienes favoritos
            </Text>
          </HStack>
        </Flex>
      );
    }
  };

  return (
    <>
      {isLoading || !inmuebles ? (
        <VistaCarga />
      ) : (
        <Flex w={["100%", "100%", "90%", "100%", "90%"]} alignSelf="center">
          <FlatList
            // style={{ width: '100%', marginTop: 5, paddingHorizontal: '2vw' }}
            contentContainerStyle={{
              justifyContent: "center",
              alignItems: "center",
            }}
            data={inmuebles}
            numColumns={numColumns}
            keyExtractor={(item) => item.ID}
            key={numColumns.toString()} // Usa el número de columnas como clave
            renderItem={({ item }) => (
              <InmuebleComponent
                key={item.ID}
                imageUri={item.fotoportada}
                titulo={item.nombre}
                estado={item.estado}
                municipio={item.municipio}
                precio={item.precio}
                id={item.ID}
                rooms={item.habitaciones}
                bath={item.wc}
                area={item.area}
              />
            )}
          />
        </Flex>
      )}
    </>
  );
};

export default FlatListInmuebles;
